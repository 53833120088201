import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Email } from '../class/email';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }
  sendemail(email:Email){
    const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify(email);
    
    // return this.http.post<Email []>(`https://e9a45i8ip3.execute-api.ap-south-1.amazonaws.com/Dev/beta-signup`)
    return this.http.post<Email>(`https://e9a45i8ip3.execute-api.ap-south-1.amazonaws.com/Dev/beta-signup`, body,{'headers':headers})
  }
}
