<div class="main-head">
  <h1 style="font-weight:600;font-size:44px;font-family: 'poppins';color:black">Free Tools</h1>
  <p>The most powerful free tools to help you in various aspects of Social Media Management.</p>
</div>
  
<!-- <a style="color: rgb(14, 13, 13);" routerLink='hashtag' class="nav-link" title="Notification">test</a> -->
<!-- 

GRADIENT BANNER DESIGN BY SIMON LURWER ON DRIBBBLE:
https://dribbble.com/shots/14101951-Banners

-->
<div class="main-container">
  <!-- <div class="heading">
    <h1 class="heading__title">Gradient Banner Cards</h1>
    <p class="heading__credits"><a class="heading__link" target="_blank" href="https://dribbble.com/sl">Design by Simon Lurwer on Dribbble</a></p>
  </div> -->
  <div class="cards">
    <a routerLink='hashtag' style="text-decoration:none">
    <div class="card card-1">
      <div class="card__icon">
       <p><img src="../../assets/hashtag Generator.png"> &nbsp; Hashtag Generater</p> 
      </div>
      <!-- <p class="card__exit"><i class="fas fa-times"></i></p> -->
      <div class="card__title"><p> Hashtag Generator furnishes you with specific hashtags which help you to increase your brand exposure</p>
      </div>
      <!-- <p class="card__apply">
        <a class="card__link" routerLink='hashtag'  ><span >Get Now </span> &nbsp; <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
      </p> -->
    </div>
  </a>
  <a routerLink='social-media-holidays' style="text-decoration:none">
    <div class="card card-1">
      <div class="card__icon">
        <p><img src="../../assets/Social Media Calender.png">&nbsp;Social Media Calender</p> 
      </div>
     
      <div class="card__title"><p>Besides the most important 2023 occasions, we've additionally set dates on the calendar schedule</p>
      </div>
      
    </div>
  </a>
  <a routerLink='qrcode' style="text-decoration:none">
    <div class="card card-1">
      <div class="card__icon">
        <p><img src="../../assets/QR Code Generator.png">&nbsp;QR CODE Generator</p> 
      </div>
     
      <div class="card__title"><p>Examine the QR codes with your cell phone to visit site pages,social media pages like (twitter,instagram,facebook.linkedin), share contact information </p>
      </div>
      
    </div>
  </a>
    <a routerLink='Images-search' style="text-decoration:none">
    <div class="card card-1">
      <div class="card__icon">
        <p><img src="../../assets/Image Search.png">&nbsp;Image Search</p> 
      </div>
     
      <div class="card__title"><p>Examine the QR codes with your cell phone to visit site pages,social media pages like (twitter,instagram,facebook.linkedin), share contact information</p>
      </div>
      
    </div>
    </a>
    <!-- <a routerLink='hashtag' style="text-decoration:none">
    <div class="card card-1">
      <div class="card__icon">
        <p><i class="fa fa-hashtag" aria-hidden="true"></i>&nbsp;Hashtag Generater</p> 
      </div>
     
      <div class="card__title"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </div>
      
    </div>
    </a> -->
   
    <!-- <div class="card card-1" role="button" routerLink='hashtag'>
      
      <div class="card__icon"><p><i class="fa fa-hashtag" aria-hidden="true"></i>&nbsp;Hashtag Generater</p></div>
     
      <div class="card__title"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </div>
    
    </div> -->
    
  </div>
</div>
