<app-header></app-header>
<router-outlet></router-outlet>
<div class="new-footer">
    <footer class="footer-area footer--light">
        <div class="footer-big">
            <!-- start .container -->
            <div class="footer-container">
                <div class="row">
                    <div class="col-md-3 col-sm-12">
                        <div class="footer-widget">
                            <div class="widget-about">
                                <img src="https://storefries.com/images/analytics/logo_White.svg" alt=""
                                    class="img-fluid">
                                <p>Start working with Storefries that can provide everything you need to engage,
                                    discover, plan & connect</p>
                                <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                                    <li class="list-inline-item"><a
                                            href="https://www.facebook.com/Storefries-100521589230785"
                                            class="rounded"
                                            target="https://www.facebook.com/Storefries-100521589230785"><i
                                                data-feather="facebook" class="fea icon-sm fea-social"></i></a></li>
                                    <li class="list-inline-item"><a href="https://www.instagram.com/storefries/"
                                            class="rounded" target="https://www.instagram.com/storefries/"><i
                                                data-feather="instagram" class="fea icon-sm fea-social"></i></a>
                                    </li>
                                    <li class="list-inline-item"><a href="https://twitter.com/Storefries1"
                                            class="rounded" target="https://twitter.com/Storefries1"><i
                                                data-feather="twitter" class="fea icon-sm fea-social"></i></a></li>
                                    <li class="list-inline-item"><a
                                            href="https://www.linkedin.com/company/storefries/" class="rounded"
                                            target="https://www.linkedin.com/company/storefries/"><i
                                                data-feather="linkedin" class="fea icon-sm fea-social"></i></a></li>
                                </ul>
                                <ul class="contact-details">
                                    <!-- <li>
                             <span class="icon-earphones"></span> Call Us:
                             <a href="tel:344-755-111">344-755-111</a>
                           </li> -->
                                    <li>
                                        <span class="icon-envelope-open"></span>
                                        <a href="mailto:support@storefries.com" style="color:#fff;">Email:&nbsp;
                                            support@storefries.com.</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- Ends: .footer-widget -->
                    </div>
                    <!-- end /.col-md-4 -->
                    <div class="col-md-2 col-sm-3">
                        <div class="footer-widget">
                            <div class="footer-menu footer-menu--1">
                                <h4 class="footer-widget-title">Product</h4>
                                <ul>
                                    <li><a href="https://storefries.com/content-discovery.html" class="text-foot"> Local content</a>
                                    </li>
                                    <li><a href="https://storefries.com/medialibrary.html" class="text-foot"> Media Library</a></li>
                                    <li><a href="https://storefries.com/composer.html" class="text-foot"> Composer</a></li>
                                    <li><a href="https://storefries.com/planner.html" class="text-foot">Plan & Scheduling</a></li>
                                    <li><a href="https://storefries.com/social-inbox.html" class="text-foot"> Social Onebox</a></li>
                                </ul>
                            </div>
                            <!-- end /.footer-menu -->
                        </div>
                        <!-- Ends: .footer-widget -->
                    </div>
                    <!-- end /.col-md-3 -->

                    <div class="col-md-2 col-sm-3">
                        <div class="footer-widget">
                            <div class="footer-menu">
                                <h4 class="footer-widget-title">Compare</h4>
                                <ul>
                                    <li>
                                        <a href="https://storefries.com/features/hootsuite-alternative.html">Hootsuite Alternative</a>
                                    </li>
                                    <li>
                                        <a href="https://storefries.com/features/later-alternative.html">Later Alternative</a>
                                    </li>
                                    <li>
                                        <a href="https://storefries.com/features/buffer-alternative.html">Buffer Alternative</a>
                                    </li>
                                    <li>
                                        <a href=".https://storefries.com/features/agorapulse-alternative.html">Agorapulse Alternative</a>
                                    </li>
                                    <li>
                                        <a href="https://storefries.com/features/socialpilot-alternative.html">Socialpilot
                                            Alternative</a>
                                    </li>
                                </ul>
                            </div>
                            <!-- end /.footer-menu -->
                        </div>
                        <!-- Ends: .footer-widget -->
                    </div>
                    <!-- end /.col-lg-3 -->

                    <div class="col-md-2 col-sm-3">
                        <div class="footer-widget">
                            <div class="footer-menu ">
                                <h4 class="footer-widget-title">Integrations</h4>
                                <ul>
                                    <li>
                                        <a href="https://storefries.com/intergration.html">Facebook</a>
                                    </li>
                                    <li>
                                        <a href="https://storefries.com/intergration.html">Twitter</a>
                                    </li>
                                    <li>
                                        <a href="https://storefries.com/intergration.html">Linkedin</a>
                                    </li>
                                    <li>
                                        <a href="https://storefries.com/intergration.html">GMB </a>
                                    </li>
                                    <li>
                                        <a href="https://storefries.com/intergration.html">Youtube </a>
                                    </li>
                                    <li>
                                        <a href="https://storefries.com/intergration.html">Instagram </a>
                                    </li>
                                    <li>
                                        <a href="https://storefries.com/intergration.html">All Integrations</a>
                                    </li>
                                </ul>
                            </div>
                            <!-- end /.footer-menu -->
                        </div>
                        <!-- Ends: .footer-widget -->
                    </div>
                    <!-- Ends: .col-lg-3 -->
                    <div class="col-md-2 col-sm-3">
                        <div class="footer-widget">
                            <div class="footer-menu ">
                                <h4 class="footer-widget-title">Resource</h4>
                                <ul>
                                    <li><a href="https://storefries.com/hc/helpcenter.html" class="text-foot"> Help Center</a></li>
                                    <li><a href="https://storefries.com/Blog/storefries-blogs.html" class="text-foot"> Blogs</a></li>
                                    <li>
                                        <a href="https://storefries.com/contactus.html">Contactus</a>
                                    </li>
                                </ul>
                            </div>
                            <!-- end /.footer-menu -->
                        </div>
                        <!-- Ends: .footer-widget -->
                    </div>
                </div>
                <!-- <p class="text-center" style="font-size:20px;font-family:poppins;">Signup for free account and get
                    special offers in first come first <br> serve basis only for limited subscribers!
                    <br><br><button type="button" class="btn btn-danger"
                        onclick="ml_webform_5579495('show')">subscribe</button>
                </p> -->
                <!-- end /.row -->
            </div>
            <!-- end /.container -->

        </div>

        <!-- end /.footer-big -->
        <div class="mini-footer">

            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="copyright-text">
                            <p style="color:#fff;">© 2022
                                <a href="#" style="color:#fff;">Storefries</a>. All rights reserved. Created by
                                <a href="#" style="color:#fff;">Aikyne</a>
                            </p>
                        </div>


                    </div>
                    <div class="col-md-6">
                        <div class="copyright-text">
                            <ul class="list-unstyled text-sm-end mb-0">
                                <li class="list-inline-item"><a href="https://storefries.com/terms.html">Terms</a></li>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <li class="list-inline-item"><a href="https://storefries.com/privacy.html">Privacy Policy</a></li>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<li class="list-inline-item"><a
                                        href="https://storefries.com/gdpr.html">GDPR</a></li>
                            </ul>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>

