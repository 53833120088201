
<div class="main">
  <h1 class="text-center">Image Search</h1>
  <p class="text-center">Need staggering copyright free pictures? Look no further. Use storefries free tool Stock Picture Search to get the best no-copyright pictures.</p>
<div class="images">
  <!-- <div class="mb-3">
    
    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" style="border: solid 1px black">
  </div> -->
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <input type="text" formControlName="fullname" class="form-control form-control-lg" (keyup.enter)="retriveimage2(keywords)" placeholder="please enter...." value="{{examples}}" name="search" [(ngModel)]="keywords"  [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }" >
    <div *ngIf="submitted && f.fullname.errors" class="invalid-feedback">
      <div *ngIf="f.fullname.errors.required">Keyword Please</div>
  </div>
  <!-- <p style="padding-top: 5px;color:red">{{error}}</p> -->
    <!-- <button type="submit"><i class="fa fa-search"></i></button> -->
    <div class="text-center" style="margin-top:10px;"><button style="width:200px" type="button" class="btn btn-primary  " (click)="retriveimage()"><i class="fa fa-search fa-lg" aria-hidden="true"></i>&nbsp; Search Images</button></div>
  </form>
  <div class="try-ex">
    <p>Examples:</p>
    
      <a  (click)="retriveimage2('Children in park')" style="color:blue;cursor: pointer;font-family: 'Poppins';font-size:15px;font-weight:300;">Children in park</a>&nbsp;&nbsp;&nbsp;
      <a  (click)="retriveimage2('People working in office')" style="color:blue;cursor: pointer;font-family: 'Poppins';font-size:15px;font-weight:300;" >People working in office</a>&nbsp;&nbsp;&nbsp;
      <a  (click)="retriveimage2('Mountains')" style="color:blue;cursor: pointer;font-family: 'Poppins';font-size:15px;font-weight:300;">Mountains</a>
   </div>
  <br>
  <div class="retrive-img">
    <!-- <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-around center" fxLayoutGap="25px" >
 
    <div *ngFor="let img of images" class="example-card card" fxFlex="calc(20%-25px)"  >    
        <img mat-card-image src="{{img.tinyurls}}" alt="Photo of a Shiba Inu" class="img-high">
       
      </div>
    
  </div> -->
  <!-- <div 
  fxLayout="row wrap" 
  fxLayout.lt-sm="column" 
  fxLayoutGap="32px" 
  fxLayoutAlign="flex-start">
<ng-container >
  <div class="card" *ngFor="let img of images" 
    fxFlex="0 1 calc(20% - 32px)"
    fxFlex.lt-md="0 1 calc(50% - 32px)"
    fxFlex.lt-sm="100%"
  ></div>
</ng-container>
</div> -->
</div>
    <!-- <div class="container">
      <div class="row" >
        <div class="card">
          <img src="http://placehold.it/200x150">
          <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
        </div>
        <div class="card">
          <img src="http://placehold.it/200x150">
          <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
        </div>
         <div class="card">
          <img src="http://placehold.it/200x150">
          <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
        </div>
         <div class="card">
          <img src="http://placehold.it/200x150">
          <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
        </div>
    
      </div>
    </div> -->
    <div class="loadingicon">
      <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
      
     
  </div>
    <div class="content">
      <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%"  *ngFor="let img of images" >
          <a href="{{img.permalinks}}" target="_blank">
          <mat-card class="" >
            <mat-card-header>
              <!-- <mat-card-title>Mountains {{num}}</mat-card-title> -->
            </mat-card-header>
            <img mat-card-image src="{{img.tinyurls}}">
            <mat-card-content>
              <span style="margin-right:2px">by</span><span style="color:blue">{{img.usernames}}</span>
              <p ><span style="margin-right:2px">on</span><span style="color:blue">{{img.platforms}}</span></p>
            </mat-card-content>
            <!-- <mat-card-actions>
              <button mat-button>LIKE</button>
              <button mat-button>SHARE</button>
            </mat-card-actions> -->
          </mat-card>
        </a>
        </div>
      </div>
      </div>
    
   <br>
</div>
</div>
