import { Injectable } from '@angular/core';
import { Hashtag } from '../class/hashtag';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class HashtagService {

  constructor(private http: HttpClient) { }
  retrievesocialhashtag(keyword: any){
    return this.http.get<Hashtag []>(`https://e9a45i8ip3.execute-api.ap-south-1.amazonaws.com/Dev/hashtag/generate?type=hashtag&keywords=${keyword}`)
    // return this.http.get<Hashtag []>(`https://brain.predis.ai/tools/get_kw_hashtags/?keywords=${keyword}`)
  }
  imagesearch(keyword: any){
    return this.http.get<Hashtag []>(`https://e9a45i8ip3.execute-api.ap-south-1.amazonaws.com/Dev/hashtag/generate?type=image&keywords=${keyword}`)
   // return this.http.get<Hashtag []>(` https://brain.predis.ai/tools/search_stock_images/?keywords=${keyword}`)
    // return this.http.get<Hashtag []>(`https://brain.predis.ai/tools/get_kw_hashtags/?keywords=${keyword}`)
  }

  // retrieveqr(qrcode: any){
    
  //   return this.http.get<Hashtag []>
  //   (`https://qrcode.tec-it.com/API/QRCode?data=https%3a%2f%2fwww.twitter.com%2f${qrcode}&errorcorrection=${qrcode}
  //   &color=%2${qrcode}&backcolor=%2${qrcode}&istransparent=${qrcode}&quietzone=${qrcode}&quietunit=${qrcode}&codepage=${qrcode}&dpi=${qrcode}`)
  // }
}
// https://qrcode.tec-it.com/API/QRCode?data=https%3a%2f%2fwww.twitter.com%2fTECIT&errorcorrection=Q&backcolor=%23ffffff&quietzone=5&quietunit=Mil&codepage=Cyrillic&dpi=500&size=Small