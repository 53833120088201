import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HashtagComponent } from './hashtag/hashtag.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {MatRadioModule} from '@angular/material/radio';
import { ToastrModule } from 'ngx-toastr';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSortModule} from '@angular/material/sort';
import { ToolsComponent } from './tools/tools.component';
import { HeaderComponent } from './header/header.component';
import { CalenderComponent } from './calender/calender.component';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { EmailValidatorDirective } from './email-validator.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QrcodeComponent } from './qrcode/qrcode.component';
import { QRCodeModule } from 'angularx-qrcode';

// 

import { ColorPickerModule } from "ngx-color-picker"

import { MatButtonModule } from "@angular/material/button"
import { MatButtonToggleModule } from "@angular/material/button-toggle"


import { MatDividerModule } from "@angular/material/divider"

import { MatIconModule } from "@angular/material/icon"

import { MatSliderModule } from "@angular/material/slider"
import { MatSlideToggleModule } from "@angular/material/slide-toggle"
import { MatSnackBarModule } from "@angular/material/snack-bar"
import { MatTabsModule } from "@angular/material/tabs"
import { MatToolbarModule } from "@angular/material/toolbar"
import { MatTooltipModule } from "@angular/material/tooltip";
import { ImagesearchComponent } from './imagesearch/imagesearch.component'
import {FlexLayoutModule} from '@angular/flex-layout';
import { NotfoundComponent } from './notfound/notfound.component';

@NgModule({
  declarations: [
    AppComponent,
    HashtagComponent,
    ToolsComponent,
    HeaderComponent,
    CalenderComponent,
    EmailValidatorDirective,
    QrcodeComponent,
    ImagesearchComponent,
    NotfoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,FormsModule, ReactiveFormsModule,HttpClientModule,
    MatCheckboxModule,MatTableModule,MatProgressSpinnerModule,MatCardModule,MatRadioModule, ToastrModule.forRoot(),
    MatExpansionModule,MatSortModule,MatSelectModule,MatFormFieldModule,MatInputModule, NgbModule,QRCodeModule,ColorPickerModule,
    MatButtonModule,MatButtonToggleModule,MatDividerModule,MatIconModule,MatSliderModule,MatSlideToggleModule,MatSnackBarModule,
    MatTabsModule,MatToolbarModule,MatTooltipModule,FlexLayoutModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
