import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { element } from '../hashtag/hashtag.component';
import { HashtagService } from '../services/hashtag.service';
import { Hashtag } from '../class/hashtag'
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-imagesearch',
  templateUrl: './imagesearch.component.html',
  styleUrls: ['./imagesearch.component.scss']
})
export class ImagesearchComponent implements OnInit {
  result:any;
  form!: FormGroup;
  constructor(private imagesearch: HashtagService,private formBuilder: FormBuilder) { }
  keywords: any;
  images:any;
  error='Keyword Please';
  submitted = false;
  examples:any;
  loading = false;
  selection = new SelectionModel<element>(true, []);
  ngOnInit(): void {
    // this.retriveimage()
    // console.log("images",this.retriveimage)
    this.form = this.formBuilder.group(
      {
        fullname: ['', Validators.required],
        username: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(20)
          ]
        ],
      },
    );
  }
  retriveimage(){
    if (this.keywords === undefined || !this.keywords || this.keywords === '' ) {

     
    }else{
    // console.log("this.keywords", this.keywords)
    this.loading = true;
    this.imagesearch.imagesearch(this.keywords).subscribe((res: any) => {
      //console.log("images",res)
      this.loading = false;
      this.images=res.data;
      // this.images = res.data.tinyurls;
      // this.result = this.images.map((person: { id: any; name: any; }) => ({ value: person.id, text: person.name }));
      
      console.log("imagesssssss",  this.images)
    })
  }
}
retriveimage2(example:any){
  this.examples=example
  this.keywords=example
  if (this.keywords === undefined || !this.keywords || this.keywords === '' ) {

   
  }else{
    this.loading = true;
  // console.log("this.keywords", this.keywords)
  this.imagesearch.imagesearch(this.keywords).subscribe((res: any) => {
    this.loading = false;
    //console.log("images",res)
    this.images=res.data;
    // this.images = res.data.tinyurls;
    // this.result = this.images.map((person: { id: any; name: any; }) => ({ value: person.id, text: person.name }));
    
    console.log("imagesssssss",  this.images)
  })
}
}
  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
}
