import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  items: any
  items2: any
  items3: any
  constructor() { }

  ngOnInit(): void {
    this.items = [
      {name: 'Local content', url: 'https://storefries.com/content-discovery.html'},
      {name: 'Media library', url: 'https://storefries.com/medialibrary.html'},
       {name: 'Composer', url: 'https://storefries.com/composer.html'},
       {name: 'Plan & Scheduling', url: 'https://storefries.com/planner.html'},
       {name: 'Social inbox', url: 'https://storefries.com/social-inbox.html'},
       {name: 'Analytics overview', url: 'https://storefries.com/analytics.html'}
      ];
      this.items2 = [
        {name: 'Geo social marketing', url: 'https://storefries.com/features/geo-social-marketing.html'},
        {name: 'Social media management', url: 'https://storefries.com/features/social-media-management.html'},
         {name: 'Content curation', url: 'https://storefries.com/features/curation.html'},
         {name: 'Social media engagement', url: 'https://storefries.com/features/social-engagement.html'},
         {name: 'Social media analytics', url: 'https://storefries.com/features/social-media-analytics.html'},
         {name: 'Small business', url: 'https://storefries.com/features/Small-Business.html'},
         {name: 'Retail', url: 'https://storefries.com/features/Retail.html'}
        ];
        this.items3 = [
          {name: 'Blogs', url: 'https://storefries.com/Blog/storefries-blogs.html'},
          {name: 'Help center', url: 'https://storefries.com/hc/helpcenter.html'},
           
          ];
  }

}
