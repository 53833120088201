import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup ,Validators} from '@angular/forms';
import{EmailService} from '../services/email.service';
import {Email} from '../class/email'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
interface industry {
  value: string;
  viewValue: string;
}
interface country {
  value: string;
  viewValue: string;
}
export interface Countries {
  code: string
  code3: string
  name: string
  number: string
  value: string;
}
@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})
export class CalenderComponent implements OnInit {
 useremail = new Email();
 submitted = false;
 form!: FormGroup;
 registerForm!: FormGroup;
 status:any
 error!: string;
 loading = false;
  constructor(private email:EmailService,private formBuilder: FormBuilder,private modalService: NgbModal) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        fullname: ['', Validators.required],
        username: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(20)
          ]
        ],
      },
    );
   
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
 
  industry: industry[] = [
    {value: 'Fashion', viewValue: 'Fashion'},
    {value: 'CPG', viewValue: 'CPG'},
    {value: 'Media/Publishing', viewValue: 'Media/Publishing'},
    {value: 'Beauty', viewValue: 'Beauty'},
    {value: 'Luxuary', viewValue: 'Luxuary'},
    {value: 'Fitness/Lifestyle', viewValue: 'Fitness/Lifestyle'},
    {value: 'Agency', viewValue: 'Agency'},
    {value: 'Food/Beverage', viewValue: 'Food/Beverage'},
    {value: 'Influencer/Blogger', viewValue: 'Influencer/Blogger'},
    {value: 'Photography', viewValue: 'Photography'},
    {value: 'others', viewValue: 'others'},
  ];
  countries: Countries[] = [
    { code: "AF", code3: "AFG", name: "Afghanistan", number: "004",value:'Afghanistan'},
    { code: "AL", code3: "ALB", name: "Albania", number: "008",value:'Afghanistan' },
    { code: "DZ", code3: "DZA", name: "Algeria", number: "012" ,value:'Afghanistan'},
    { code: "AS", code3: "ASM", name: "American Samoa", number: "016",value:'Afghanistan' },
    { code: "AD", code3: "AND", name: "Andorra", number: "020",value:'Afghanistan' },
    { code: "AO", code3: "AGO", name: "Angola", number: "024",value:'Afghanistan' },
    { code: "AI", code3: "AIA", name: "Anguilla", number: "660",value:'Afghanistan' },
    { code: "AQ", code3: "ATA", name: "Antarctica", number: "010",value:'Afghanistan' },
    { code: "AG", code3: "ATG", name: "Antigua and Barbuda", number: "028",value:'Afghanistan' },
    { code: "AR", code3: "ARG", name: "Argentina", number: "032",value:'Afghanistan' },
    { code: "AM", code3: "ARM", name: "Armenia", number: "051",value:'Afghanistan' },
    { code: "AW", code3: "ABW", name: "Aruba", number: "533",value:'Afghanistan' },
    { code: "AU", code3: "AUS", name: "Australia", number: "036",value:'Afghanistan' },
    { code: "AT", code3: "AUT", name: "Austria", number: "040",value:'Afghanistan' },
    { code: "AZ", code3: "AZE", name: "Azerbaijan", number: "031",value:'Afghanistan' },
    { code: "BS", code3: "BHS", name: "Bahamas (the)", number: "044",value:'Afghanistan' },
    { code: "BH", code3: "BHR", name: "Bahrain", number: "048",value:'Afghanistan' },
    { code: "BD", code3: "BGD", name: "Bangladesh", number: "050",value:'Afghanistan' },
    { code: "BB", code3: "BRB", name: "Barbados", number: "052",value:'Afghanistan' },
    { code: "BY", code3: "BLR", name: "Belarus", number: "112",value:'Afghanistan' },
    { code: "BE", code3: "BEL", name: "Belgium", number: "056",value:'Afghanistan' },
    { code: "BZ", code3: "BLZ", name: "Belize", number: "084",value:'Afghanistan' },
    { code: "BJ", code3: "BEN", name: "Benin", number: "204",value:'Afghanistan' },
    { code: "BM", code3: "BMU", name: "Bermuda", number: "060",value:'Afghanistan' },
    { code: "BT", code3: "BTN", name: "Bhutan", number: "064",value:'Afghanistan' },
    { code: "BO", code3: "BOL", name: "Bolivia (Plurinational State of)", number: "068",value:'Afghanistan' },
    { code: "BQ", code3: "BES", name: "Bonaire, Sint Eustatius and Saba", number: "535",value:'Afghanistan' },
    { code: "BA", code3: "BIH", name: "Bosnia and Herzegovina", number: "070",value:'Afghanistan' },
    { code: "BW", code3: "BWA", name: "Botswana", number: "072",value:'Afghanistan' },
    { code: "BV", code3: "BVT", name: "Bouvet Island", number: "074",value:'Afghanistan' },
    { code: "BR", code3: "BRA", name: "Brazil", number: "076",value:'Afghanistan' },
    { code: "IO", code3: "IOT", name: "British Indian Ocean Territory (the)", number: "086",value:'Afghanistan' },
    { code: "BN", code3: "BRN", name: "Brunei Darussalam", number: "096",value:'Afghanistan' },
    { code: "BG", code3: "BGR", name: "Bulgaria", number: "100",value:'Afghanistan' },
    { code: "BF", code3: "BFA", name: "Burkina Faso", number: "854",value:'Afghanistan' },
    { code: "BI", code3: "BDI", name: "Burundi", number: "108",value:'Afghanistan' },
    { code: "CV", code3: "CPV", name: "Cabo Verde", number: "132",value:'Afghanistan' },
    { code: "KH", code3: "KHM", name: "Cambodia", number: "116",value:'Afghanistan' },
    { code: "CM", code3: "CMR", name: "Cameroon", number: "120",value:'Afghanistan' },
    { code: "CA", code3: "CAN", name: "Canada", number: "124",value:'Afghanistan' },
    { code: "KY", code3: "CYM", name: "Cayman Islands (the)", number: "136",value:'Afghanistan' },
    { code: "CF", code3: "CAF", name: "Central African Republic (the)", number: "140",value:'Afghanistan' },
    { code: "TD", code3: "TCD", name: "Chad", number: "148",value:'Afghanistan' },
    { code: "CL", code3: "CHL", name: "Chile", number: "152",value:'Afghanistan' },
    { code: "CN", code3: "CHN", name: "China", number: "156",value:'Afghanistan' },
    { code: "CX", code3: "CXR", name: "Christmas Island", number: "162",value:'Afghanistan' },
    { code: "CC", code3: "CCK", name: "Cocos (Keeling) Islands (the)", number: "166",value:'Afghanistan' },
    { code: "CO", code3: "COL", name: "Colombia", number: "170",value:'Afghanistan' },
    { code: "KM", code3: "COM", name: "Comoros (the)", number: "174",value:'Afghanistan' },
    { code: "CD", code3: "COD", name: "Congo (the Democratic Republic of the)", number: "180",value:'Afghanistan' },
    { code: "CG", code3: "COG", name: "Congo (the)", number: "178",value:'Afghanistan' },
    { code: "CK", code3: "COK", name: "Cook Islands (the)", number: "184",value:'Afghanistan' },
    { code: "CR", code3: "CRI", name: "Costa Rica", number: "188",value:'Afghanistan' },
    { code: "HR", code3: "HRV", name: "Croatia", number: "191",value:'Afghanistan' },
    { code: "CU", code3: "CUB", name: "Cuba", number: "192",value:'Afghanistan' },
    { code: "CW", code3: "CUW", name: "Curaçao", number: "531",value:'Afghanistan' },
    { code: "CY", code3: "CYP", name: "Cyprus", number: "196",value:'Afghanistan' },
    { code: "CZ", code3: "CZE", name: "Czechia", number: "203",value:'Afghanistan' },
    { code: "CI", code3: "CIV", name: "Côte d'Ivoire", number: "384",value:'Afghanistan' },
    { code: "DK", code3: "DNK", name: "Denmark", number: "208",value:'Afghanistan' },
    { code: "DJ", code3: "DJI", name: "Djibouti", number: "262",value:'Afghanistan' },
    { code: "DM", code3: "DMA", name: "Dominica", number: "212",value:'Afghanistan' },
    { code: "DO", code3: "DOM", name: "Dominican Republic (the)", number: "214",value:'Afghanistan' },
    { code: "EC", code3: "ECU", name: "Ecuador", number: "218",value:'Afghanistan' },
    { code: "EG", code3: "EGY", name: "Egypt", number: "818",value:'Afghanistan' },
    { code: "SV", code3: "SLV", name: "El Salvador", number: "222",value:'Afghanistan' },
    { code: "GQ", code3: "GNQ", name: "Equatorial Guinea", number: "226",value:'Afghanistan' },
    { code: "ER", code3: "ERI", name: "Eritrea", number: "232",value:'Afghanistan' },
    { code: "EE", code3: "EST", name: "Estonia", number: "233",value:'Afghanistan' },
    { code: "SZ", code3: "SWZ", name: "Eswatini", number: "748",value:'Afghanistan' },
    { code: "ET", code3: "ETH", name: "Ethiopia", number: "231",value:'Afghanistan' },
    { code: "FK", code3: "FLK", name: "Falkland Islands (the) [Malvinas]", number: "238",value:'Afghanistan' },
    { code: "FO", code3: "FRO", name: "Faroe Islands (the)", number: "234",value:'Afghanistan' },
    { code: "FJ", code3: "FJI", name: "Fiji", number: "242",value:'Afghanistan' },
    { code: "FI", code3: "FIN", name: "Finland", number: "246",value:'Afghanistan' },
    { code: "FR", code3: "FRA", name: "France", number: "250",value:'Afghanistan' },
    { code: "GF", code3: "GUF", name: "French Guiana", number: "254",value:'Afghanistan' },
    { code: "PF", code3: "PYF", name: "French Polynesia", number: "258",value:'Afghanistan' },
    { code: "TF", code3: "ATF", name: "French Southern Territories (the)", number: "260",value:'Afghanistan' },
    { code: "GA", code3: "GAB", name: "Gabon", number: "266",value:'Afghanistan' },
    { code: "GM", code3: "GMB", name: "Gambia (the)", number: "270",value:'Afghanistan' },
    { code: "GE", code3: "GEO", name: "Georgia", number: "268",value:'Afghanistan' },
    { code: "DE", code3: "DEU", name: "Germany", number: "276",value:'Afghanistan' },
    { code: "GH", code3: "GHA", name: "Ghana", number: "288",value:'Afghanistan' },
    { code: "GI", code3: "GIB", name: "Gibraltar", number: "292",value:'Afghanistan' },
    { code: "GR", code3: "GRC", name: "Greece", number: "300",value:'Afghanistan' },
    { code: "GL", code3: "GRL", name: "Greenland", number: "304",value:'Afghanistan' },
    { code: "GD", code3: "GRD", name: "Grenada", number: "308",value:'Afghanistan' },
    { code: "GP", code3: "GLP", name: "Guadeloupe", number: "312",value:'Afghanistan' },
    { code: "GU", code3: "GUM", name: "Guam", number: "316",value:'Afghanistan' },
    { code: "GT", code3: "GTM", name: "Guatemala", number: "320",value:'Afghanistan' },
    { code: "GG", code3: "GGY", name: "Guernsey", number: "831",value:'Afghanistan' },
    { code: "GN", code3: "GIN", name: "Guinea", number: "324",value:'Afghanistan' },
    { code: "GW", code3: "GNB", name: "Guinea-Bissau", number: "624",value:'Afghanistan' },
    { code: "GY", code3: "GUY", name: "Guyana", number: "328",value:'Afghanistan' },
    { code: "HT", code3: "HTI", name: "Haiti", number: "332",value:'Afghanistan' },
    { code: "HM", code3: "HMD", name: "Heard Island and McDonald Islands", number: "334",value:'Afghanistan' },
    { code: "VA", code3: "VAT", name: "Holy See (the)", number: "336",value:'Afghanistan' },
    { code: "HN", code3: "HND", name: "Honduras", number: "340",value:'Afghanistan' },
    { code: "HK", code3: "HKG", name: "Hong Kong", number: "344",value:'Afghanistan' },
    { code: "HU", code3: "HUN", name: "Hungary", number: "348",value:'Afghanistan' },
    { code: "IS", code3: "ISL", name: "Iceland", number: "352",value:'Afghanistan' },
    { code: "IN", code3: "IND", name: "India", number: "356",value:'Afghanistan' },
    { code: "ID", code3: "IDN", name: "Indonesia", number: "360",value:'Afghanistan' },
    { code: "IR", code3: "IRN", name: "Iran (Islamic Republic of)", number: "364",value:'Afghanistan' },
    { code: "IQ", code3: "IRQ", name: "Iraq", number: "368",value:'Afghanistan' },
    { code: "IE", code3: "IRL", name: "Ireland", number: "372",value:'Afghanistan' },
    { code: "IM", code3: "IMN", name: "Isle of Man", number: "833",value:'Afghanistan' },
    { code: "IL", code3: "ISR", name: "Israel", number: "376",value:'Afghanistan' },
    { code: "IT", code3: "ITA", name: "Italy", number: "380",value:'Afghanistan' },
    { code: "JM", code3: "JAM", name: "Jamaica", number: "388",value:'Afghanistan' },
    { code: "JP", code3: "JPN", name: "Japan", number: "392",value:'Afghanistan' },
    { code: "JE", code3: "JEY", name: "Jersey", number: "832",value:'Afghanistan' },
    { code: "JO", code3: "JOR", name: "Jordan", number: "400",value:'Afghanistan' },
    { code: "KZ", code3: "KAZ", name: "Kazakhstan", number: "398",value:'Afghanistan' },
    { code: "KE", code3: "KEN", name: "Kenya", number: "404",value:'Afghanistan' },
    { code: "KI", code3: "KIR", name: "Kiribati", number: "296",value:'Afghanistan' },
    { code: "KP", code3: "PRK", name: "Korea (the Democratic People's Republic of)", number: "408",value:'Afghanistan' },
    { code: "KR", code3: "KOR", name: "Korea (the Republic of)", number: "410",value:'Afghanistan' },
    { code: "KW", code3: "KWT", name: "Kuwait", number: "414",value:'Afghanistan' },
    { code: "KG", code3: "KGZ", name: "Kyrgyzstan", number: "417",value:'Afghanistan' },
    { code: "LA", code3: "LAO", name: "Lao People's Democratic Republic (the)", number: "418",value:'Afghanistan' },
    { code: "LV", code3: "LVA", name: "Latvia", number: "428",value:'Afghanistan' },
    { code: "LB", code3: "LBN", name: "Lebanon", number: "422",value:'Afghanistan' },
    { code: "LS", code3: "LSO", name: "Lesotho", number: "426",value:'Afghanistan' },
    { code: "LR", code3: "LBR", name: "Liberia", number: "430",value:'Afghanistan' },
    { code: "LY", code3: "LBY", name: "Libya", number: "434",value:'Afghanistan' },
    { code: "LI", code3: "LIE", name: "Liechtenstein", number: "438",value:'Afghanistan' },
    { code: "LT", code3: "LTU", name: "Lithuania", number: "440",value:'Afghanistan' },
    { code: "LU", code3: "LUX", name: "Luxembourg", number: "442",value:'Afghanistan' },
    { code: "MO", code3: "MAC", name: "Macao", number: "446",value:'Afghanistan' },
    { code: "MG", code3: "MDG", name: "Madagascar", number: "450",value:'Afghanistan' },
    { code: "MW", code3: "MWI", name: "Malawi", number: "454",value:'Afghanistan' },
    { code: "MY", code3: "MYS", name: "Malaysia", number: "458",value:'Afghanistan' },
    { code: "MV", code3: "MDV", name: "Maldives", number: "462",value:'Afghanistan' },
    { code: "ML", code3: "MLI", name: "Mali", number: "466",value:'Afghanistan' },
    { code: "MT", code3: "MLT", name: "Malta", number: "470",value:'Afghanistan' },
    { code: "MH", code3: "MHL", name: "Marshall Islands (the)", number: "584",value:'Afghanistan' },
    { code: "MQ", code3: "MTQ", name: "Martinique", number: "474",value:'Afghanistan' },
    { code: "MR", code3: "MRT", name: "Mauritania", number: "478",value:'Afghanistan' },
    { code: "MU", code3: "MUS", name: "Mauritius", number: "480",value:'Afghanistan' },
    { code: "YT", code3: "MYT", name: "Mayotte", number: "175",value:'Afghanistan' },
    { code: "MX", code3: "MEX", name: "Mexico", number: "484",value:'Afghanistan' },
    { code: "FM", code3: "FSM", name: "Micronesia (Federated States of)", number: "583",value:'Afghanistan' },
    { code: "MD", code3: "MDA", name: "Moldova (the Republic of)", number: "498",value:'Afghanistan' },
    { code: "MC", code3: "MCO", name: "Monaco", number: "492",value:'Afghanistan' },
    { code: "MN", code3: "MNG", name: "Mongolia", number: "496",value:'Afghanistan' },
    { code: "ME", code3: "MNE", name: "Montenegro", number: "499",value:'Afghanistan' },
    { code: "MS", code3: "MSR", name: "Montserrat", number: "500",value:'Afghanistan' },
    { code: "MA", code3: "MAR", name: "Morocco", number: "504",value:'Afghanistan' },
    { code: "MZ", code3: "MOZ", name: "Mozambique", number: "508",value:'Afghanistan' },
    { code: "MM", code3: "MMR", name: "Myanmar", number: "104",value:'Afghanistan' },
    { code: "NA", code3: "NAM", name: "Namibia", number: "516",value:'Afghanistan' },
    { code: "NR", code3: "NRU", name: "Nauru", number: "520",value:'Afghanistan' },
    { code: "NP", code3: "NPL", name: "Nepal", number: "524",value:'Afghanistan' },
    { code: "NL", code3: "NLD", name: "Netherlands (the)", number: "528",value:'Afghanistan' },
    { code: "NC", code3: "NCL", name: "New Caledonia", number: "540",value:'Afghanistan' },
    { code: "NZ", code3: "NZL", name: "New Zealand", number: "554",value:'Afghanistan' },
    { code: "NI", code3: "NIC", name: "Nicaragua", number: "558",value:'Afghanistan' },
    { code: "NE", code3: "NER", name: "Niger (the)", number: "562",value:'Afghanistan' },
    { code: "NG", code3: "NGA", name: "Nigeria", number: "566",value:'Afghanistan' },
    { code: "NU", code3: "NIU", name: "Niue", number: "570",value:'Afghanistan' },
    { code: "NF", code3: "NFK", name: "Norfolk Island", number: "574",value:'Afghanistan' },
    { code: "MP", code3: "MNP", name: "Northern Mariana Islands (the)", number: "580",value:'Afghanistan' },
    { code: "NO", code3: "NOR", name: "Norway", number: "578",value:'Afghanistan' },
    { code: "OM", code3: "OMN", name: "Oman", number: "512",value:'Afghanistan' },
    { code: "PK", code3: "PAK", name: "Pakistan", number: "586",value:'Afghanistan' },
    { code: "PW", code3: "PLW", name: "Palau", number: "585",value:'Afghanistan' },
    { code: "PS", code3: "PSE", name: "Palestine, State of", number: "275",value:'Afghanistan' },
    { code: "PA", code3: "PAN", name: "Panama", number: "591",value:'Afghanistan' },
    { code: "PG", code3: "PNG", name: "Papua New Guinea", number: "598",value:'Afghanistan' },
    { code: "PY", code3: "PRY", name: "Paraguay", number: "600",value:'Afghanistan' },
    { code: "PE", code3: "PER", name: "Peru", number: "604",value:'Afghanistan' },
    { code: "PH", code3: "PHL", name: "Philippines (the)", number: "608",value:'Afghanistan' },
    { code: "PN", code3: "PCN", name: "Pitcairn", number: "612",value:'Afghanistan' },
    { code: "PL", code3: "POL", name: "Poland", number: "616",value:'Afghanistan' },
    { code: "PT", code3: "PRT", name: "Portugal", number: "620",value:'Afghanistan' },
    { code: "PR", code3: "PRI", name: "Puerto Rico", number: "630",value:'Afghanistan' },
    { code: "QA", code3: "QAT", name: "Qatar", number: "634",value:'Afghanistan' },
    { code: "MK", code3: "MKD", name: "Republic of North Macedonia", number: "807",value:'Afghanistan' },
    { code: "RO", code3: "ROU", name: "Romania", number: "642",value:'Afghanistan' },
    { code: "RU", code3: "RUS", name: "Russian Federation (the)", number: "643",value:'Afghanistan' },
    { code: "RW", code3: "RWA", name: "Rwanda", number: "646",value:'Afghanistan' },
    { code: "RE", code3: "REU", name: "Réunion", number: "638",value:'Afghanistan' },
    { code: "BL", code3: "BLM", name: "Saint Barthélemy", number: "652",value:'Afghanistan' },
    { code: "SH", code3: "SHN", name: "Saint Helena, Ascension and Tristan da Cunha", number: "654",value:'Afghanistan' },
    { code: "KN", code3: "KNA", name: "Saint Kitts and Nevis", number: "659",value:'Afghanistan' },
    { code: "LC", code3: "LCA", name: "Saint Lucia", number: "662",value:'Afghanistan' },
    { code: "MF", code3: "MAF", name: "Saint Martin (French part)", number: "663",value:'Afghanistan' },
    { code: "PM", code3: "SPM", name: "Saint Pierre and Miquelon", number: "666",value:'Afghanistan' },
    { code: "VC", code3: "VCT", name: "Saint Vincent and the Grenadines", number: "670",value:'Afghanistan' },
    { code: "WS", code3: "WSM", name: "Samoa", number: "882",value:'Afghanistan' },
    { code: "SM", code3: "SMR", name: "San Marino", number: "674",value:'Afghanistan' },
    { code: "ST", code3: "STP", name: "Sao Tome and Principe", number: "678",value:'Afghanistan' },
    { code: "SA", code3: "SAU", name: "Saudi Arabia", number: "682",value:'Afghanistan' },
    { code: "SN", code3: "SEN", name: "Senegal", number: "686",value:'Afghanistan' },
    { code: "RS", code3: "SRB", name: "Serbia", number: "688",value:'Afghanistan' },
    { code: "SC", code3: "SYC", name: "Seychelles", number: "690",value:'Afghanistan' },
    { code: "SL", code3: "SLE", name: "Sierra Leone", number: "694",value:'Afghanistan' },
    { code: "SG", code3: "SGP", name: "Singapore", number: "702",value:'Afghanistan' },
    { code: "SX", code3: "SXM", name: "Sint Maarten (Dutch part)", number: "534",value:'Afghanistan' },
    { code: "SK", code3: "SVK", name: "Slovakia", number: "703",value:'Afghanistan' },
    { code: "SI", code3: "SVN", name: "Slovenia", number: "705",value:'Afghanistan' },
    { code: "SB", code3: "SLB", name: "Solomon Islands", number: "090",value:'Afghanistan' },
    { code: "SO", code3: "SOM", name: "Somalia", number: "706",value:'Afghanistan' },
    { code: "ZA", code3: "ZAF", name: "South Africa", number: "710",value:'Afghanistan' },
    { code: "GS", code3: "SGS", name: "South Georgia and the South Sandwich Islands", number: "239",value:'Afghanistan' },
    { code: "SS", code3: "SSD", name: "South Sudan", number: "728",value:'Afghanistan' },
    { code: "ES", code3: "ESP", name: "Spain", number: "724",value:'Afghanistan' },
    { code: "LK", code3: "LKA", name: "Sri Lanka", number: "144",value:'Afghanistan' },
    { code: "SD", code3: "SDN", name: "Sudan (the)", number: "729",value:'Afghanistan' },
    { code: "SR", code3: "SUR", name: "Suriname", number: "740",value:'Afghanistan' },
    { code: "SJ", code3: "SJM", name: "Svalbard and Jan Mayen", number: "744",value:'Afghanistan' },
    { code: "SE", code3: "SWE", name: "Sweden", number: "752",value:'Afghanistan' },
    { code: "CH", code3: "CHE", name: "Switzerland", number: "756",value:'Afghanistan' },
    { code: "SY", code3: "SYR", name: "Syrian Arab Republic", number: "760",value:'Afghanistan' },
    { code: "TW", code3: "TWN", name: "Taiwan", number: "158",value:'Afghanistan' },
    { code: "TJ", code3: "TJK", name: "Tajikistan", number: "762",value:'Afghanistan' },
    { code: "TZ", code3: "TZA", name: "Tanzania, United Republic of", number: "834",value:'Afghanistan' },
    { code: "TH", code3: "THA", name: "Thailand", number: "764",value:'Afghanistan' },
    { code: "TL", code3: "TLS", name: "Timor-Leste", number: "626",value:'Afghanistan' },
    { code: "TG", code3: "TGO", name: "Togo", number: "768",value:'Afghanistan' },
    { code: "TK", code3: "TKL", name: "Tokelau", number: "772",value:'Afghanistan' },
    { code: "TO", code3: "TON", name: "Tonga", number: "776",value:'Afghanistan' },
    { code: "TT", code3: "TTO", name: "Trinidad and Tobago", number: "780",value:'Afghanistan' },
    { code: "TN", code3: "TUN", name: "Tunisia", number: "788",value:'Afghanistan' },
    { code: "TR", code3: "TUR", name: "Turkey", number: "792",value:'Afghanistan' },
    { code: "TM", code3: "TKM", name: "Turkmenistan", number: "795",value:'Afghanistan' },
    { code: "TC", code3: "TCA", name: "Turks and Caicos Islands (the)", number: "796",value:'Afghanistan' },
    { code: "TV", code3: "TUV", name: "Tuvalu", number: "798",value:'Afghanistan' },
    { code: "UG", code3: "UGA", name: "Uganda", number: "800",value:'Afghanistan' },
    { code: "UA", code3: "UKR", name: "Ukraine", number: "804",value:'Afghanistan' },
    { code: "AE", code3: "ARE", name: "United Arab Emirates (the)", number: "784",value:'Afghanistan' },
    { code: "GB", code3: "GBR", name: "United Kingdom of Great Britain and Northern Ireland (the)", number: "826",value:'Afghanistan' },
    { code: "UM", code3: "UMI", name: "United States Minor Outlying Islands (the)", number: "581",value:'Afghanistan' },
    { code: "US", code3: "USA", name: "United States of America (the)", number: "840",value:'Afghanistan' },
    { code: "UY", code3: "URY", name: "Uruguay", number: "858",value:'Afghanistan' },
    { code: "UZ", code3: "UZB", name: "Uzbekistan", number: "860",value:'Afghanistan' },
    { code: "VU", code3: "VUT", name: "Vanuatu", number: "548",value:'Afghanistan' },
    { code: "VE", code3: "VEN", name: "Venezuela (Bolivarian Republic of)", number: "862",value:'Afghanistan' },
    { code: "VN", code3: "VNM", name: "Viet Nam", number: "704",value:'Afghanistan' },
    { code: "VG", code3: "VGB", name: "Virgin Islands (British)", number: "092",value:'Afghanistan' },
    { code: "VI", code3: "VIR", name: "Virgin Islands (U.S.)", number: "850",value:'Afghanistan' },
    { code: "WF", code3: "WLF", name: "Wallis and Futuna", number: "876",value:'Afghanistan' },
    { code: "EH", code3: "ESH", name: "Western Sahara", number: "732",value:'Afghanistan' },
    { code: "YE", code3: "YEM", name: "Yemen", number: "887",value:'Afghanistan' },
    { code: "ZM", code3: "ZMB", name: "Zambia", number: "894",value:'Afghanistan' },
    { code: "ZW", code3: "ZWE", name: "Zimbabwe", number: "716",value:'Afghanistan' },
    { code: "AX", code3: "ALA", name: "Åland Islands", number: "248",value:'Afghanistan' }
  ];
  insertcustomer(content:any) {
    // if (this.useremail.email === undefined || !this.useremail.email || this.useremail.email === '' )
    if (this.useremail.email === undefined || !this.useremail.email || this.useremail.email === '' ) {

      console.log("Keyword is required.")
    }
    this.loading = true;
    // console.log("kkkkkkkkkkkkkk")
    this.email.sendemail(this.useremail).subscribe(res => {
      console.log("emai-------------",res)
    
      this.loading = false;
      this.modalService.open(content, { centered: true ,size: 'lg'});
    //   this.viewcustomer();
     this.useremail=new Email;
    },err=>{
      console.log("err",err.error.status)
      alert(err.error.status)
    }
   
    )
  
}
onSubmit(): void {
  this.submitted = true;

  if (this.form.invalid) {
    return;
  }
}
openVerticallyCentered(content:any) {
  this.modalService.open(content, { centered: true ,size: 'lg'});
}
}
