import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ColorPickerService, Cmyk } from 'ngx-color-picker';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from
  '@angular/forms';
@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QrcodeComponent implements OnInit {
  isActive!: boolean;
  tabName!: string;
  correctionlevel='M';
  codePage='Cyrillic';
  size='Large';
  quietZoneUnit='Px';
  quietzone=10;
  dpi=400;
  qr = 'https://qrcode.tec-it.com/API/QRCode?data=https%3a%2f%2fwww.twitter.com%2fTECIT&errorcorrection=Q&color=%2black&backcolor=%23ffffff&istransparent=True&quietzone=100&quietunit=Px&codepage=Cyrillic&dpi=98&method=download'
  qrdata !: string;
  url='https://storefries.com';
  profile='strorefries1';
  tweet='Test';
  instagram='storefries';
  linkedin='storefries';
  facebook='storefries'
  myModel: any;
  rawdata!: string;
  rawdata1!: string;
  qrcontent: any;
  rawfield: any;
  urlfield: any;
  twitterfield: any;
  tweetfield: any;
  checktab = 'url';
  ErrorCorrectionLevel = [
    {
      name: 'Low', value: "L",
    },
    {
      name: 'Medium', value: "M",
    },
    {
      name: 'Quartil', value: "Q",
    }, {
      name: 'High', value: "H",
    }
  ]
  CodePage = [
    {
      name: 'ANSI', value: "Ansi",
    },
    {
      name: 'Cyrillic', value: "Cyrillic",
    }
  ]
  QuietZoneUnit = [
    {
      name: 'Pixel', value: "Px",
    },
    {
      name: 'Millimeter', value: "Millimeter",
    },
    {
      name: 'Inch', value: "Inch",
    },
    {
      name: 'Modules', value: "Modules",
    },
    {
      name: 'Mils', value: "Mils",
    }
  ]
  Size = [
    {
      name: 'Small', value: "Small",
    },
    {
      name: 'Medium', value: "Large",
    },
    {
      name: 'Large', value: "Large",
    }
  ]
  color='3ffffff';
  correctionlevels='M';
  codePages='Cyrillic';
  sizes='Large';
  quietZoneUnits='';
  // myForm = new FormGroup({})  Instantiating our form

  // get f() { return this.myForm.controls; }
  constructor(private cpService: ColorPickerService, formBuilder: FormBuilder) {
    this.myModel = '123';
    //   this.myForm = formBuilder.group({     
    //     dpi: ['', [Validators.min(96), Validators.max(600)]]
    // });
  }

  onBlurMethod() {
    alert(this.myModel)
  }

  show = true;
  // selected = 'option2';

  onEnter(qrdata: any) {
    console.log("-----------s---------------------", qrdata)
    /* if (!qrdata) {
      alert("sentence required");
    } */
    this.rawfield = qrdata
    console.log("type of qrdata", typeof qrdata);

    if (typeof qrdata == 'string' && qrdata.trim().length === 0) {
      console.log(".................................")
      alert("sentence required");
      return;
    }

    /* if (qrdata == '' || qrdata == null || qrdata == undefined) {
      console.log(".................................")
      alert("sentence required");
    } */
    /* if (qrdata === 13 ) {
      alert("you just pressed the enter key");
      
    } */
    // this.qrdata='subramani'
    // this.rawdata = 'https://qrcode.tec-it.com/API/QRCode?data=${qrdata}&backcolor=%23ffffff' 
    this.rawdata = `https://qrcode.tec-it.com/API/QRCode?data=${qrdata}&backcolor=%23ffffff&method=download`
    console.log("---------rawdata----------------", this.rawdata)

  }
  onEnterurl(qrdata: any) {
    console.log("-----------s---------------------", qrdata)
    /* if (!qrdata) {
      alert("sentence required");
    } */
    this.urlfield = qrdata;
    console.log("type of qrdata", typeof qrdata);

    if (typeof qrdata == 'string' && qrdata.trim().length === 0) {
      console.log(".................................")
      alert("sentence required");
      return;
    }

    /* if (qrdata == '' || qrdata == null || qrdata == undefined) {
      console.log(".................................")
      alert("sentence required");
    } */
    /* if (qrdata === 13 ) {
      alert("you just pressed the enter key");
      
    } */
    // this.qrdata='subramani'
    // this.rawdata = 'https://qrcode.tec-it.com/API/QRCode?data=${qrdata}&backcolor=%23ffffff' 
    this.rawdata = `https://qrcode.tec-it.com/API/QRCode?data=${qrdata}&backcolor=%23ffffff&method=download`
    console.log("-----------s---------------------", this.rawdata)
  }

  onEnterprofile(qrdata: any) {
    console.log("-----------s---------------------", qrdata)
    /* if (!qrdata) {
      alert("sentence required");
    } */
    this.twitterfield = qrdata
    console.log("type of qrdata", typeof qrdata);

    if (typeof qrdata == 'string' && qrdata.trim().length === 0) {
      console.log(".................................")
      alert("sentence required");
      return;
    }

    /* if (qrdata == '' || qrdata == null || qrdata == undefined) {
      console.log(".................................")
      alert("sentence required");
    } */
    /* if (qrdata === 13 ) {
      alert("you just pressed the enter key");
      
    } */
    // this.qrdata='subramani'
    // this.rawdata = 'https://qrcode.tec-it.com/API/QRCode?data=${qrdata}&backcolor=%23ffffff' 
    this.rawdata = `https://qrcode.tec-it.com/API/QRCode?data=https%3a%2f%2fwww.twitter.com%2f${qrdata}&backcolor=%23ffffff&method=download`


    console.log("-----------s---------------------", this.rawdata)
  }
  onEntertweet(qrdata: any) {
    this.tweetfield = qrdata;
    console.log("-----------s---------------------", qrdata)
    /* if (!qrdata) {
      alert("sentence required");
    } */
    console.log("type of qrdata", typeof qrdata);

    if (typeof qrdata == 'string' && qrdata.trim().length === 0) {
      console.log(".................................")
      alert("sentence required");
      return;
    }

    /* if (qrdata == '' || qrdata == null || qrdata == undefined) {
      console.log(".................................")
      alert("sentence required");
    } */
    /* if (qrdata === 13 ) {
      alert("you just pressed the enter key");
      
    } */
    // this.qrdata='subramani'
    // this.rawdata = 'https://qrcode.tec-it.com/API/QRCode?data=${qrdata}&backcolor=%23ffffff' 
    this.rawdata = `https://qrcode.tec-it.com/API/QRCode?data=https%3a%2f%2fwww.twitter.com%2fhome%3fstatus%3d%2${qrdata}&backcolor=%23ffffff`


    console.log("-----------s---------------------", this.rawdata)
  }

  onEnterinsta(qrdata: any) {
    this.tweetfield = qrdata;
    console.log("-----------s---------------------", qrdata)
    /* if (!qrdata) {
      alert("sentence required");
    } */
    console.log("type of qrdata", typeof qrdata);

    if (typeof qrdata == 'string' && qrdata.trim().length === 0) {
      console.log(".................................")
      alert("sentence required");
      return;
    }

    /* if (qrdata == '' || qrdata == null || qrdata == undefined) {
      console.log(".................................")
      alert("sentence required");
    } */
    /* if (qrdata === 13 ) {
      alert("you just pressed the enter key");
      
    } */
    // this.qrdata='subramani'
    // this.rawdata = 'https://qrcode.tec-it.com/API/QRCode?data=${qrdata}&backcolor=%23ffffff' 
    this.rawdata = `https://qrcode.tec-it.com/API/QRCode?data=https://www.instagram.com/${qrdata}/&backcolor=%23ffffff`


    console.log("-----------s---------------------", this.rawdata)
  }
  onEnterlinkedin(qrdata: any) {
    this.tweetfield = qrdata;
    console.log("-----------s---------------------", qrdata)
    /* if (!qrdata) {
      alert("sentence required");
    } */
    console.log("type of qrdata", typeof qrdata);

    if (typeof qrdata == 'string' && qrdata.trim().length === 0) {
      console.log(".................................")
      alert("sentence required");
      return;
    }

    /* if (qrdata == '' || qrdata == null || qrdata == undefined) {
      console.log(".................................")
      alert("sentence required");
    } */
    /* if (qrdata === 13 ) {
      alert("you just pressed the enter key");
      
    } */
    // this.qrdata='subramani'
    // this.rawdata = 'https://qrcode.tec-it.com/API/QRCode?data=${qrdata}&backcolor=%23ffffff' 
    this.rawdata = `https://qrcode.tec-it.com/API/QRCode?data=https://www.linkedin.com/company/${qrdata}/&backcolor=%23ffffff`


    console.log("-----------s---------------------", this.rawdata)
  }
  onEnterfacebook(qrdata: any) {
    this.tweetfield = qrdata;
    console.log("-----------s---------------------", qrdata)
    /* if (!qrdata) {
      alert("sentence required");
    } */
    console.log("type of qrdata", typeof qrdata);

    if (typeof qrdata == 'string' && qrdata.trim().length === 0) {
      console.log(".................................")
      alert("sentence required");
      return;
    }

    /* if (qrdata == '' || qrdata == null || qrdata == undefined) {
      console.log(".................................")
      alert("sentence required");
    } */
    /* if (qrdata === 13 ) {
      alert("you just pressed the enter key");
      
    } */
    // this.qrdata='subramani'
    // this.rawdata = 'https://qrcode.tec-it.com/API/QRCode?data=${qrdata}&backcolor=%23ffffff' 
    this.rawdata = `https://qrcode.tec-it.com/API/QRCode?data=https://www.facebook.com/${qrdata}/&backcolor=%23ffffff`


    console.log("-----------s---------------------", this.rawdata)
  }
  targetqr(value: any) {
    this.checktab = value;
    switch (value) {
      case 'url':
        this.rawdata = "https://qrcode.tec-it.com/API/QRCode?data=https://storefries.com/&backcolor=%23ffffff&size=Medium&method=download";
        break;
      case 'twitter':
        this.rawdata = "https://qrcode.tec-it.com/API/QRCode?data=https%3a%2f%2fwww.twitter.com%2fstorefries1&backcolor=%23ffffff&size=Medium&method=download";
        break;
      case 'tweet':
        this.rawdata = "https://qrcode.tec-it.com/API/QRCode?data=https%3a%2f%2fwww.twitter.com%2fhome%3fstatus%3d%23Everyone+wants+to+be+a+%23stylist.%0a+If+you+%23know+that%e2%80%99s+your+%23calling%2c+%0athen+you%0a%0a&backcolor=%23ffffff";
        break;
      case 'insta':
        this.rawdata = "https://qrcode.tec-it.com/API/QRCode?data=https://www.instagram.com/storefries/";
        break;
      case 'linkedin':
        this.rawdata = "https://qrcode.tec-it.com/API/QRCode?data=https://www.linkedin.com/company/storefries/";
        break;
      case 'facebook':
        this.rawdata = "https://qrcode.tec-it.com/API/QRCode?data=https://www.facebook.com/profile.php?id=100077974131077";
        break;

      default:
        this.rawdata = "https://qrcode.tec-it.com/API/QRCode?data=https://storefries.com/&backcolor=%23ffffff&size=Medium&method=download";
    }
    console.log("--------s-------cccccccc--------------", value)
  }
  checktabfun() {
    // if (this.checktab =='twitter' || this.checktab =="tweet") {
    //   console.log("checktab",this.checktab)

    // }
    //   console.log("checktab",this.checktab)

    //   console.log("-----------sclose---------------", this.qrcontent)
    //   console.log("ccccthis.correctionlevel", this.correctionlevel)
    //   console.log("ccccthis.codePage", this.codePage)
    //   console.log("ccccthis.size", this.size)
    //   console.log("ccccthis.quietZoneUnit", this.quietZoneUnit)
    //   console.log("ccccthis.quietzone", this.quietzone)
    //   console.log("ccccthis.dpi", this.dpi);
    //   console.log("ccccthis.dpi",  this.color)
    //   console.log("ccccthis.rawfield", this.rawfield)
    //   console.log("ccccthis.urlfield", this.urlfield)
    //   console.log("ccccthis.twitterfield", this.twitterfield);



    //   this.url = this.url ? this.url : ""

    //   this.correctionlevel ? this.correctionlevel : 'ffffff'
    //   // this.rawdata = `https://qrcode.tec-it.com/API/QRCode?${this.url}status%3d%2${qrdata}&backcolor=${this.correctionlevel}`

    //   // this.rawdata=`https://qrcode.tec-it.com/API/QRCode?data=${qrdata}&backcolor=%23ffffff&size=${this.urldatas3}&method=download` 
    //   // this.rawdata1=`https://qrcode.tec-it.com/API/QRCode?data=${qrdata}&backcolor=%23ffffff&size=${this.urldatas3}&method=download` 
    //   this.rawdata
    //   // this.rawdata1=`https://qrcode.tec-it.com/API/QRCode?data=${qrdata}&backcolor=%23ffffff&size=${this.urldatas3}&method=download`
    //   console.log("-------close--this.rawdata-------------", this.rawdata)


    // subbu
    switch (this.checktab) {
      // case 'rawdata':
      //   this.rawdata = `https://qrcode.tec-it.com/API/QRCode?data=${this.rawfield}&backcolor=${this.color}&errorcorrection=${this.correctionlevel}&quietzone=${this.quietzone}&quietunit=${this.quietZoneUnit}&codepage=${this.codePage}&dpi=${this.dpi}&method=download`;
      //   break;
      case 'url':

        let params = '';

        params = this.url ? `${params}&data=${this.url}` : params;
        params = this.color ? `${params}&backcolor=${this.color}` : params;
        params = this.correctionlevel ? `${params}&errorcorrection=${this.correctionlevel}` : params;
        params = this.quietzone ? `${params}&quietzone=${this.quietzone}` : params;
        params = this.quietZoneUnit ? `${params}&quietunit=${this.quietZoneUnit}` : params;
        params = this.codePage ? `${params}&codepage=${this.codePage}` : params;
        params = this.size ? `${params}&size=${this.size}` : params;
       
        params = this.dpi ? `${params}&dpi=${this.dpi}` : params;
        //params = `&data=${this.url}&backcolor=${this.color}&errorcorrection=${this.correctionlevel}&quietzone=${this.quietzone}&quietunit=${this.quietZoneUnit}&codepage=${this.codePage}&dpi=${this.dpi}`
        console.log("params",params)
        this.rawdata = `https://qrcode.tec-it.com/API/QRCode?${params}&method=download`;
        //this.rawdata = `https://qrcode.tec-it.com/API/QRCode?data=${this.url}&backcolor=${this.color}&errorcorrection=${this.correctionlevel}&quietzone=${this.quietzone}&quietunit=${this.quietZoneUnit}&codepage=${this.codePage}&dpi=${this.dpi}&method=download`;
        console.log("-------close--this.rawdata-------------", this.rawdata)
        break;
      case 'twitter':
        // this.rawdata = `https://qrcode.tec-it.com/API/QRCode?data=https%3a%2f%2fwww.twitter.com%2f${this.url}&backcolor=${this.color}&errorcorrection=${this.correctionlevel}&quietzone=${this.quietzone}&quietunit=${this.quietZoneUnit}&codepage=${this.codePage}&dpi=${this.dpi}&method=download`;
        // this.rawdata = "https://qrcode.tec-it.com/API/QRCode?data=https%3a%2f%2fwww.twitter.com%2fTECIT&backcolor=%23ffffff&size=Medium&method=download";
        let paramstwitter = '';

        paramstwitter = this.profile ? `${paramstwitter}&data=https%3a%2f%2fwww.twitter.com%2f${this.profile}` : paramstwitter;
        paramstwitter = this.color ? `${paramstwitter}&backcolor=${this.color}` : paramstwitter;
        paramstwitter = this.correctionlevel ? `${paramstwitter}&errorcorrection=${this.correctionlevel}` : paramstwitter;
        paramstwitter = this.quietzone ? `${paramstwitter}&quietzone=${this.quietzone}` : paramstwitter;
        paramstwitter = this.quietZoneUnit ? `${paramstwitter}&quietunit=${this.quietZoneUnit}` : paramstwitter;
        paramstwitter = this.codePage ? `${paramstwitter}&codepage=${this.codePage}` : paramstwitter;
        paramstwitter = this.size ? `${paramstwitter}&size=${this.size}` : paramstwitter;
       
        paramstwitter = this.dpi ? `${paramstwitter}&dpi=${this.dpi}` : paramstwitter;
        //params = `&data=${this.url}&backcolor=${this.color}&errorcorrection=${this.correctionlevel}&quietzone=${this.quietzone}&quietunit=${this.quietZoneUnit}&codepage=${this.codePage}&dpi=${this.dpi}`
        console.log("params",paramstwitter)
        this.rawdata = `https://qrcode.tec-it.com/API/QRCode?${paramstwitter}&method=download`;
        //this.rawdata = `https://qrcode.tec-it.com/API/QRCode?data=${this.url}&backcolor=${this.color}&errorcorrection=${this.correctionlevel}&quietzone=${this.quietzone}&quietunit=${this.quietZoneUnit}&codepage=${this.codePage}&dpi=${this.dpi}&method=download`;
        console.log("-------close--this.rawdata-------------", this.rawdata)
        break;
      case 'tweet':
        let paramstweet = '';

        paramstweet = this.tweet ? `${paramstweet}&data=https%3a%2f%2fwww.twitter.com%2fhome%3fstatus%3d%23${this.tweet}` : paramstweet;
        paramstweet = this.color ? `${paramstweet}&backcolor=${this.color}` : paramstweet;
        paramstweet = this.correctionlevel ? `${paramstweet}&errorcorrection=${this.correctionlevel}` : paramstweet;
        paramstweet = this.quietzone ? `${paramstweet}&quietzone=${this.quietzone}` : paramstweet;
        paramstweet = this.quietZoneUnit ? `${paramstweet}&quietunit=${this.quietZoneUnit}` : paramstweet;
        paramstweet = this.codePage ? `${paramstweet}&codepage=${this.codePage}` : paramstweet;
        paramstweet = this.size ? `${paramstweet}&size=${this.size}` : paramstweet;
       
        paramstwitter = this.dpi ? `${paramstweet}&dpi=${this.dpi}` : paramstweet;
        //params = `&data=${this.url}&backcolor=${this.color}&errorcorrection=${this.correctionlevel}&quietzone=${this.quietzone}&quietunit=${this.quietZoneUnit}&codepage=${this.codePage}&dpi=${this.dpi}`
        console.log("params",paramstweet)
        this.rawdata = `https://qrcode.tec-it.com/API/QRCode?${paramstweet}&method=download`;
        console.log("-------close--this.rawdata-------------", this.rawdata)  
      // this.rawdata = "https://qrcode.tec-it.com/API/QRCode?data=https%3a%2f%2fwww.twitter.com%2fhome%3fstatus%3d%23QRCode+tweet+generated+on+https%3a%2f%2fqrcode.tec-it.com.+Thanks+to+%40TECIT&backcolor=%23ffffff&size=Medium&method=download";
        break;
        case 'insta':
          let insta = '';

          insta = this.instagram? `${insta}&data=https://www.instagram.com/${this.instagram}/` : insta;
          console.log("params",this.instagram)
          insta = this.color ? `${insta}&backcolor=${this.color}` : insta;
          insta = this.correctionlevel ? `${insta}&errorcorrection=${this.correctionlevel}` : insta;
          insta = this.quietzone ? `${insta}&quietzone=${this.quietzone}` : insta;
          insta = this.quietZoneUnit ? `${insta}&quietunit=${this.quietZoneUnit}` : insta;
          insta = this.codePage ? `${insta}&codepage=${this.codePage}` : insta;
          insta = this.size ? `${insta}&size=${this.size}` : insta;
       
          insta = this.dpi ? `${insta}&dpi=${this.dpi}` : insta;
        //params = `&data=${this.url}&backcolor=${this.color}&errorcorrection=${this.correctionlevel}&quietzone=${this.quietzone}&quietunit=${this.quietZoneUnit}&codepage=${this.codePage}&dpi=${this.dpi}`
        console.log("params",insta)
        this.rawdata = `https://qrcode.tec-it.com/API/QRCode?${insta}&method=download`;
        console.log("-------close--this.rawdata-------------", this.rawdata) 
          // this.rawdata = "https://qrcode.tec-it.com/API/QRCode?data=https://www.instagram.com/storefries/";
          break;
          case 'linkedin':
            let linkedin = '';

            linkedin = this.linkedin? `${linkedin}&data=https://www.linkedin.com/company/${this.linkedin}/` : linkedin;
            console.log("params",this.instagram)
            linkedin = this.color ? `${linkedin}&backcolor=${this.color}` : linkedin;
            linkedin = this.correctionlevel ? `${linkedin}&errorcorrection=${this.correctionlevel}` : linkedin;
            linkedin = this.quietzone ? `${linkedin}&quietzone=${this.quietzone}` : linkedin;
            linkedin = this.quietZoneUnit ? `${linkedin}&quietunit=${this.quietZoneUnit}` : linkedin;
            linkedin = this.codePage ? `${linkedin}&codepage=${this.codePage}` : linkedin;
            linkedin = this.size ? `${linkedin}&size=${this.size}` : linkedin;
         
            linkedin = this.dpi ? `${linkedin}&dpi=${this.dpi}` : linkedin;
          //params = `&data=${this.url}&backcolor=${this.color}&errorcorrection=${this.correctionlevel}&quietzone=${this.quietzone}&quietunit=${this.quietZoneUnit}&codepage=${this.codePage}&dpi=${this.dpi}`
          // console.log("params",linkedin)
          this.rawdata = `https://qrcode.tec-it.com/API/QRCode?${linkedin}&method=download`;
          console.log("-------close--this.rawdata-------------", this.rawdata) 
        // this.rawdata = "https://qrcode.tec-it.com/API/QRCode?data=https://www.linkedin.com/company/storefries/";
        break;
        case 'facebook':
          let facebook = '';

          facebook = this.facebook? `${facebook}&data=https://www.facebook.com/${this.facebook}/` : facebook;
            // console.log("params",this.instagram)
            facebook = this.color ? `${facebook}&backcolor=${this.color}` : facebook;
            facebook = this.correctionlevel ? `${facebook}&errorcorrection=${this.correctionlevel}` : facebook;
            facebook = this.quietzone ? `${facebook}&quietzone=${this.quietzone}` : facebook;
            facebook = this.quietZoneUnit ? `${facebook}&quietunit=${this.quietZoneUnit}` : facebook;
            facebook = this.codePage ? `${facebook}&codepage=${this.codePage}` : facebook;
            facebook = this.size ? `${facebook}&size=${this.size}` : facebook;
         
            facebook = this.dpi ? `${facebook}&dpi=${this.dpi}` : facebook;
          //params = `&data=${this.url}&backcolor=${this.color}&errorcorrection=${this.correctionlevel}&quietzone=${this.quietzone}&quietunit=${this.quietZoneUnit}&codepage=${this.codePage}&dpi=${this.dpi}`
          // console.log("params",linkedin)
          this.rawdata = `https://qrcode.tec-it.com/API/QRCode?${facebook}&method=download`;
          console.log("-------close--this.rawdata-------------", this.rawdata) 
        // this.rawdata = "https://qrcode.tec-it.com/API/QRCode?data=https://www.facebook.com/profile.php?id=100077974131077";
        break;

      default:
        this.rawdata = "https://qrcode.tec-it.com/API/QRCode?data=https://storefries.com/&backcolor=%23ffffff&size=Medium&method=download";
    }

  }

  selectedLevel :any;
  selectedLevel2 :any;
  selectedLevel3 :any;
  selectedLevel4 :any;

  result: any;
  selected() {
    // console.log(this.selectedLevel)
    // console.log(this.selectedLevel2)
    // console.log(this.selectedLevel3)
    // console.log(this.selectedLevel4)
    // if (this.selectedLevel==undefined || this.selectedLevel2==undefined||this.selectedLevel3==undefined||this.selectedLevel4==undefined) {
    //   this.correctionlevel
    //   this.codePage
    //   this.size

    // }
 
    this.correctionlevel = this.selectedLevel;
    this.codePage = this.selectedLevel2;
    this.size = this.selectedLevel3;
    this.quietZoneUnit = this.selectedLevel4;
    // console.log("this.correctionlevel", this.correctionlevels)
    // console.log("this.codePage", this.codePages)
    // console.log("this.size", this.sizes)
    // console.log("this.quietZoneUnit", this.quietZoneUnits)
  }
  ngOnInit() {
    this.tabName = 'url'
    this.rawdata = 'https://qrcode.tec-it.com/API/QRCode?data=QR+Code+Generator+by+TEC-IT&backcolor=%23ffffff&method=download'
    this.onEnter(this.qrdata)
    var grade = '';


  }
  public toggle: boolean = false;

  public rgbaText: string = 'rgba(165, 26, 214, 0.2)';

  public arrayColors: any = {
    color1: '#2883e9',
    color2: '#e920e9',
    color3: 'rgb(255,245,0)',
    color4: 'rgb(236,64,64)',
    color5: 'rgba(45,208,45,1)'
  };

  public selectedColor: string = 'color1';

  public color1: string = '#2889e9';
  public color2: string = '#e920e9';
  public color3: string = '#fff500';
  public color4: string = 'rgb(236,64,64)';
  public color5: string = 'rgba(45,208,45,1)';
  public color6: string = '#1973c0';
  public color7: string = '#f200bd';
  public color8: string = '#a8ff00';
  public color9: string = '#278ce2';
  public color10: string = '#0a6211';
  public color11: string = '#f2ff00';
  public color12: string = '#f200bd';
  public color13: string = 'rgba(0,255,0,0.5)';
  public color14: string = 'rgb(0,255,255)';
  public color15: string = 'rgb(255,0,0)';
  public color16: string = '#a51ad633';
  public color17: string = '#666666';
  public color18: string = '#fa8072';
  public color19: string = '#f88888';

  public cmykValue: string = '';

  public cmykColor: Cmyk = new Cmyk(0, 0, 0, 0);



  public onEventLog(event: string, data: any): void {
    console.log(event, data);
    this.color = data;
    console.log("klnkndvkl", this.color)
  }

  public onChangeColor(color: string): void {
    console.log('Color changed:', color);
  }

  public onChangeColorCmyk(color: string): Cmyk {
    const hsva = this.cpService.stringToHsva(color);

    if (hsva) {
      const rgba = this.cpService.hsvaToRgba(hsva);

      return this.cpService.rgbaToCmyk(rgba);
    }

    return new Cmyk(0, 0, 0, 0);
  }

  public onChangeColorHex8(color: string): string {
    const hsva = this.cpService.stringToHsva(color, true);

    if (hsva) {
      return this.cpService.outputFormat(hsva, 'rgba', null);
    }

    return '';
  }
}
