
<div class="heading">
    <p>Social Media
    Holidays Calender
    2023</p>
</div>
<div class="trend">
    <!-- <p>
    Never miss a social media moment this year. </p>
<p>From #WorldEmojiDay to #NationalSelfieDay, keep your</p><p> Instagram up-to-date and plan your content ahead of </p><p>time with our free 2022 social media holidays calendar.</p>
<p class="text-center" style="margin-top:50px;font-family: 'poppins';font-weight: 600;">Enter your email to get your free</p>
<p class="text-center"  style="font-family: 'poppins';font-weight: 600;">downloadable calendar and holiday guide</p> -->
<p>
  There are plenty of essential days that social media marketers and managers can share on their enterprise pages to create more engagement with the audience. Yet, chances are, you may not recognize it these days until you see your competitors posting about it.

</p>
<p>Besides the most important 2023 occasions, we've additionally set dates on the calendar schedule. While it's not essential to praise every social media event or important holiday, monitoring all these special times of the year can help you with conceptualizing thoughts for your advertising and carrying out the fun, themed posts in your social media business content schedule.
</p>
<p>To assist you with monitoring all this set of exceptional occasions, and events here's a Social Media Holiday Calendar for the upcoming year.</p>
<p><b>Enter your email ID down below and hit the press button
  free social media calendar will arrive at your inbox in a few minutes
  </b></p>
</div>
<div class="mail">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
  
    
      <input  type="text" formControlName="fullname" class="form-control form-control-lg" [(ngModel)]="useremail.email" placeholder="Enter the valid email" 
      [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }" />
      <div *ngIf="submitted && f.fullname.errors" class="invalid-feedback">
        <div *ngIf="f.fullname.errors.required">Please email</div>
      </div> 
      <div class="loadingicon">
        <mat-spinner *ngIf="loading" [diameter]="30"></mat-spinner>
        
       
    </div>
    <br>
    <!-- <p>
    <mat-form-field appearance="fill" style="width:100%">
      <mat-label>category</mat-label>
      <mat-select >
        <mat-option *ngFor="let industry of industry" [value]="industry.value">
          {{industry.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </p>

    <p>
        <mat-form-field appearance="fill" style="width:100%">
          <mat-label>Select country</mat-label>
          <mat-select >
            <mat-option *ngFor="let country of countries" [value]="country.value" >
              {{country.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        </p> -->
        <input type="checkbox" value="Car"> &nbsp;
        <small>Yes, I would like to receive email marketing communication from Storefries. I understand that I can unsubscribe at any time.</small>
        <p>
        <input type="submit" role="button" class="button " value="DOWNLOAD NOW" (click)="insertcustomer(content)" >
        
      
    </p>
  </form>
  
  <!-- <div id="mlb2-5628574" class="ml-form-embedContainer ml-subscribe-form ml-subscribe-form-5628574">
    <div class="ml-form-align-center">
    <div class="ml-form-embedWrapper embedForm">
    <div class="ml-form-embedBody ml-form-embedBodyHorizontal row-form">
    <div class="ml-form-embedContent" >
    <h4> Social Media Holiday Calendar</h4>
    <p>Signup for the unique and newly updated social media holiday calendar with all the events across the world</p>
    </div>
    <form class="ml-block-form" action="https://static.mailerlite.com/webforms/submit/s3c6p0" data-code="s3c6p0" method="post" target="_blank">
    <div class="ml-form-formContent horozintalForm">
    <div class="ml-form-horizontalRow">
    <div class="ml-input-horizontal">
    <div style="width:100%" class="horizontal-fields">
    <div class="ml-field-group ml-field-email ml-validate-email ml-validate-required">
    <label>Email</label>
    <input type="email" class="form-control" data-inputmask="" name="fields[email]" placeholder="" autocomplete="email">
    </div>
    </div>
    </div>
    <div class="ml-button-horizontal primary labelsOn">
    <button type="submit" class="primary">Subscribe</button>
    <button disabled="disabled" style="display:none" type="button" class="loading"><div class="ml-form-embedSubmitLoad"></div><span class="sr-only">Loading...</span></button>
    </div>
    </div>
    </div>
    <input type="hidden" name="ml-submit" value="1">
    <div class="ml-mobileButton-horizontal">
    <button type="submit" class="primary">Subscribe</button>
    <button disabled="disabled" style="display:none" type="button" class="loading"><div class="ml-form-embedSubmitLoad"></div><span class="sr-only">Loading...</span></button>
    </div>
    <input type="hidden" name="anticsrf" value="true">
    </form>
    </div>
    <div class="ml-form-successBody row-success" style="display:none">
    <div class="ml-form-successContent">
    <h4>Thank you!</h4>
    <p>You have successfully joined our subscriber list.</p>
    </div>
    </div>
    </div>
    </div>
    </div>
    <script>
      function ml_webform_success_5628574(){var r=ml_jQuery||jQuery;r(".ml-subscribe-form-5628574 .row-success").show(),r(".ml-subscribe-form-5628574 .row-form").hide()}
    </script>
    <img src="https://track.mailerlite.com/webforms/o/5628574/s3c6p0?v1662633178" width="1" height="1" style="max-width:1px;max-height:1px;visibility:hidden;padding:0;margin:0;display:block" alt="." border="0">
    <script src="https://static.mailerlite.com/js/w/webforms.min.js?v7316d10e2931a97c7b0f5c7e7e7be3ea" type="text/javascript"></script> -->
    </div> 
    <ng-template #content let-modal>
      <div class="modal-header">
        <!-- <button ></button> -->
        <i type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')" class="fa fa-times" aria-hidden="true"></i>
      </div>
      <div style="padding-top:35px;padding-bottom:35px">
     <div class="row">
      <div class="col-sm-6 col-12">
      <h1 class="text-center" style="padding-top:20px;font-family: 'poppins';font-weight:800;font-size:25px;color:green">SUCCESS !</h1>
      <p class="text-center"style="padding:10px;font-family: 'poppins';font-weight:400;font-size:18px">Your Free 2023 Social Media Holidays Calendar is on the way to your inbox!</p>
      <p class="text-center"><a style="text-decoration:underline" target="_blank" href="https://dhuhkrmpnuqm.cloudfront.net/Social+Media+Calendar+2023.pdf">hurry up? Click here to download</a></p> 
      <div class="text-center">
        <a href="https://app.storefries.com/login" class="button3">Sign up for storefries</a>
      </div> 
    </div>
      <div class="col-sm-6 col-12">
        <a href="https://app.storefries.com/login">
        <img src="https://storefries.com/images/analytics/logo.svg" style="width:90%;padding:30px">
      </a>
      </div>
     </div>
    </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
      </div>
    </ng-template>
   
    <!-- <button class="btn btn-outline-primary mb-2 me-2" (click)="openVerticallyCentered(content)">Modal vertically centered</button> -->
<br>
