import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ToolsComponent } from './tools/tools.component';
import { HashtagComponent } from './hashtag/hashtag.component';
import { CalenderComponent } from './calender/calender.component';
import { QrcodeComponent } from './qrcode/qrcode.component';
import { ImagesearchComponent } from './imagesearch/imagesearch.component';
import { NotfoundComponent } from './notfound/notfound.component';
const routes: Routes = [
  { path: 'free-tools', component: ToolsComponent,data :{ title:'Free AI Tools | Storefries'} },
  { path: 'hashtag', component: HashtagComponent ,data :{ title:'Free Instagram Hashtag Generator | Storefries'}},
  { path: 'social-media-holidays', component: CalenderComponent ,data :{ title:'social Media Holidays Calender | Storefries'}},
  { path: 'qrcode', component: QrcodeComponent,data :{ title:'Free Qr-code Generator'} },
  { path: 'Images-search', component: ImagesearchComponent,data :{ title:'Free Images Search | Storefries'} },
  { path: '', component: ToolsComponent,data :{ title:'Free AI Tools | Storefries'} },
  {path: '404', component: NotfoundComponent},
  {path: '**', redirectTo: '/404'}

]; 

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
