import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { HashtagService } from '../services/hashtag.service';
import { Hashtag } from '../class/hashtag'
import { ToastrService } from 'ngx-toastr';
import {MatSort} from '@angular/material/sort';
declare var jQuery: any;
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

export class element {
  idElementFormation?: number;
  elementFormation!: string;
  tempsElement?: number; // ca doit etre en date
  prixElement!: number;
}
export interface UserData {
  id: string;
  name: string;
  progress: string;
  fruit: string;
}

/** Constants used to fill up our data base. */
const FRUITS: string[] = [
  'blueberry',
  'lychee',
  'kiwi',
  'mango',
  'peach',
  'lime',
  'pomegranate',
  'pineapple',
];
const NAMES: string[] = [
  'Maia',
  'Asher',
  'Olivia',
  'Atticus',
  'Amelia',
  'Jack',
  'Charlotte',
  'Theodore',
  'Isla',
  'Oliver',
  'Isabella',
  'Jasper',
  'Cora',
  'Levi',
  'Violet',
  'Arthur',
  'Mia',
  'Thomas',
  'Elizabeth',
];
@Component({
  selector: 'app-hashtag',
  templateUrl: './hashtag.component.html',
  styleUrls: ['./hashtag.component.scss']
})
export class HashtagComponent implements OnInit {
  @ViewChild(MatSort)
  sort!: MatSort;
  relevancyhashdata!: Hashtag[];
  counthashdata!: Hashtag[];
 
  tabName = '';
  tabNamehash = 'Hashtag-Relevancy';
  keywords: any;
  keywords2: any;
  form!: FormGroup;
  submitted = false;
  panelOpenState = false;
  relevan = new Hashtag();

  dataSource:any
  constructor(private hashtag: HashtagService, private formBuilder: FormBuilder,private toastrService: ToastrService) { }

  ngOnInit(): void {
    (function ($) {
      $(function () {
        $('nav ul li > a:not(:only-child)').click(function (e: any) {
          $(this)
            .siblings('.nav-dropdown')
            .slideToggle()
          $('.nav-dropdown')
            .not($(this).siblings())
            .hide()
          e.stopPropagation()
        })
        $('html').click(function () {
          $('.nav-dropdown').hide()
        })
        // Toggle open and close nav styles on click
        $('#nav-toggle').click(function () {
          $('nav ul').slideToggle();
        });
        $('#nav-toggle').on('click', function () {
          this.classList.toggle('active')
        })
      })
    })(jQuery)

    this.form = this.formBuilder.group(
      {
        fullname: ['', Validators.required],
        username: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(20)
          ]
        ],
      },
    );
   
  }
  displayedColumns4: string[] = ['select', 'Tag', 'Count'];
  selection = new SelectionModel<element>(true, []);
  totalCal: any;
  totalHashtag = 0;
  count: any
  select: any
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    this.select = this.selection.selected.length;
    const numRows = this.relevancyhashdata.length;
    return numSelected === numRows;
  }

  toggleSelectionAll(): void {

    this.isAllSelected()
      ? this.selection.clear()
      : this.relevancyhashdata.forEach((row: any) => this.selection.select(row));
    this.computeTotal();
  }
  toggledeleteAll(): void {
    this.selection.clear()
    this.select = this.selection.selected.length;
    this.computeTotal();
  }

  toggleSelection(row: element): void {

    if (this.select >= 20) {
      console.log(".....Not allowed to add more then 30 hashtag.....")
      // alert('Not allowed to add more then 30 hashtag')
      this.toastrService.error('Message Error!', 'Title Error!');
      // this.selection.toggle(row);
      // this.computeTotal();
    }
    else{
      this.selection.toggle(row);
      this.computeTotal();
      
    }
    // this.selection.toggle(row);
    // this.computeTotal();
  }

  
  computeTotal(): void {

    this.totalCal = this.relevancyhashdata
      .filter((e: any) => this.selection.isSelected(e))
      .map((e) => e.tag)

    // this.totalHashtag = this.totalCal.length;

    // if (this.totalCal.length >= 30) {
    //   console.log("this.totalCal....................................................")
    // }
  }
  isAllSelected2() {

    const numSelected = this.selection.selected.length;
    this.select = this.selection.selected.length;
    const numRows = this.counthashdata.length;

    return numSelected === numRows;

  }

  toggleSelectionAll2(): void {

    this.isAllSelected()
      ? this.selection.clear()
      : this.counthashdata.forEach((row: any) => this.selection.select(row));
    this.computeTotal2();
  }
  toggledeleteAll2(): void {
    this.selection.clear()
    this.computeTotal2();
  }

  toggleSelection2(row: element): void {
    this.selection.toggle(row);
    this.computeTotal2();
  }

  computeTotal2(): void {

    this.totalCal = this.counthashdata
      .filter((e: any) => this.selection.isSelected(e))
      .map((e) => e.tag)

  }
  copyInputMessage(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }
  copyInputMessaged(inputElement: any) {
    console.log("----------------", inputElement)
  }
  /* To copy any Text */
  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  k: any
  loading = false;
  retrivehashtag() {
   
    if (this.keywords === undefined || !this.keywords || this.keywords === '' ) {

      console.log("Keyword is required.")
    }

    else {
      this.loading = true;
      this.hashtag.retrievesocialhashtag(this.keywords).subscribe((res: any) => {
        this.dataSource = new MatTableDataSource(res)
        if (this.sort) // check it is defined.
        {
            this.dataSource.sort = this.sort;
        }
        this.relevancyhashdata = res.tags_by_relevancy;
        this.counthashdata = res.tags_by_count;
        
        

        console.log("subbu", this.relevancyhashdata)
        console.log("subbu2", this.counthashdata)

        this.loading = false;

        //  console.log(" relevancyhashdata",this.relevancyhashdata)

        if (res.tags_by_count.length > 0) {
          this.counthashdata = res.tags_by_count.filter((obj: any) => {

            if (obj.count >= 1000000000) {

              obj.count = (obj.count / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
              
            }
            if (obj.count >= 1000000) {

              obj.count = (obj.count / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
              
            }
            if (obj.count >= 1000) {

              obj.count = (obj.count / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
              
            }

            return obj;

          })

          console.log(" counthashdata", this.counthashdata)

        }

        if (res.tags_by_relevancy.length > 0) {
          this.relevancyhashdata = res.tags_by_relevancy.filter((obj: any) => {

            if (obj.count >= 1000000000) {

              obj.count = (obj.count / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
              
            }
            if (obj.count >= 1000000) {

              obj.count = (obj.count / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
              
            }
            if (obj.count >= 1000) {

              obj.count = (obj.count / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
              
            }

            return obj;

          })

          console.log(" relevancyhashdata", this.relevancyhashdata)

        }
      })
    }
  }
  retrivehashtag2(example:any) {
    console.log("lklklkl",example)
    this.num4=example
    this.keywords = example;
    if (this.keywords === undefined || !this.keywords || this.keywords === '' ) {

      // console.log("Keyword is required.")
    }

    else {
      this.loading = true;
      this.hashtag.retrievesocialhashtag(this.keywords).subscribe((res: any) => {

        this.relevancyhashdata = res.tags_by_relevancy;
        this.counthashdata = res.tags_by_count;

        // console.log("subbu", this.relevancyhashdata)
        // console.log("subbu2", this.counthashdata)

        this.loading = false;

        //  console.log(" relevancyhashdata",this.relevancyhashdata)

        if (res.tags_by_count.length > 0) {
          this.counthashdata = res.tags_by_count.filter((obj: any) => {

            if (obj.count >= 1000000000) {

              obj.count = (obj.count / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
              
            }
            if (obj.count >= 1000000) {

              obj.count = (obj.count / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
              
            }
            if (obj.count >= 1000) {

              obj.count = (obj.count / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
              
            }

            return obj;

          })

          console.log(" counthashdata", this.counthashdata)

        }

        if (res.tags_by_relevancy.length > 0) {
          this.relevancyhashdata = res.tags_by_relevancy.filter((obj: any) => {

            if (obj.count >= 1000000000) {

              obj.count = (obj.count / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
              
            }
            if (obj.count >= 1000000) {

              obj.count = (obj.count / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
              
            }
            if (obj.count >= 1000) {

              obj.count = (obj.count / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
              
            }

            return obj;

          })

          console.log(" relevancyhashdata", this.relevancyhashdata)

        }
      })
    }
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
  }
  placeId:any
  myFunc(num1:any) {
    console.log(num1);//here you will get input value through ng-model
  }
  selectedCompany:any
  
  mycompanylist = [
    { id: 1, name: 'Company 1' },
    { id: 1, name: 'Company 1' },
    { id: 1, name: 'Company 1' },
    { id: 1, name: 'Company 1' }
  ];
  num4:any
  myFunc2(num1:any) {
    console.log("------------------------------",num1);//here you will get input value through ng-model
    this.num4 = num1
    this.keywords = num1
  }
}

