import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute ,ResolveEnd,ActivatedRouteSnapshot} from '@angular/router';
import { filter, map } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  // title = 'hashtag';
  tabName = '';
  constructor(private readonly title: Title, private readonly router: Router) { }

  ngOnInit() {
    this.setupTitleListener();
  }

  private setupTitleListener() {
    this.router.events.pipe(filter(e => e instanceof ResolveEnd)).subscribe((e: any) => {
      const { data } = getDeepestChildSnapshot(e.state.root);
      if(data?.title){
        console.log(data.title);
        this.title.setTitle(data.title);
      }
    });
  }

}
function getDeepestChildSnapshot(snapshot: ActivatedRouteSnapshot) {
  let deepestChild = snapshot.firstChild;
  while (deepestChild?.firstChild) {
    deepestChild = deepestChild.firstChild
  };
  return deepestChild || snapshot
}