<div class="nav-res"  >
<nav class="navbar navbar-expand-lg  " >
	<a class="navbar-brand logo" href="#">
        <img src="../../assets/logo (1)-01.png" style="height:70px">
    </a>
	<!-- <button class="" >
    <span class="navbar-toggler-icon" ></span>
  </button> -->
  <i style="margin-right:10px" class=" navbar-toggler fa fa-bars fa-2x " aria-hidden="true" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"></i>
	<div class="collapse navbar-collapse" id="navbarSupportedContent" >
		<ul class="navbar-nav mr-auto res"  >
            <li class="nav-item dropdown">
				<a class="nav-link dropdown-toggle head" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
					aria-haspopup="true" aria-expanded="false" style="font-weight:500;font-size:18px;font-family: 'poppins';color:black">
					Product
				</a>
				<div class="dropdown-menu" aria-labelledby="navbarDropdown">
					<a class="dropdown-item" *ngFor="let item of items; let i = index"
						[href]="item.url">{{item.name}}</a>
                        
				</div>
			</li>
			<li class="nav-item dropdown">
				<a class="nav-link dropdown-toggle head" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
					aria-haspopup="true" aria-expanded="false" style="font-weight:500;font-size:18px;font-family: 'poppins';color:black">
					Solution
				</a>
				<div class="dropdown-menu" aria-labelledby="navbarDropdown">
					<a class="dropdown-item" *ngFor="let item of items2; let i = index"
						[href]="item.url">{{item.name}}</a>
				</div>
			</li>
			<li class="nav-item">
				<a class="nav-link head" href="https://storefries.com/pricing.html" style="font-weight:500;font-size:18px;font-family: 'poppins';color:black">Price</a>
			</li>
            <li class="nav-item dropdown">
				<a class="nav-link dropdown-toggle head " href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
					aria-haspopup="true" aria-expanded="false" style="font-weight:500;font-size:18px;font-family: 'poppins';color:black">
					Resources
				</a>
				<div class="dropdown-menu" aria-labelledby="navbarDropdown">
					<a class="dropdown-item" *ngFor="let item of items3; let i = index"
						[href]="item.url">{{item.name}}</a>
				</div>
			</li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <li class="nav-item">
				<a class=" btn btn-outline-success"  href="https://app.storefries.com/signup" style="width:120px">Sign up</a>
			</li>&nbsp;&nbsp;&nbsp;&nbsp;
            <li class="nav-item">
				<a class=" btn btn-success"  href="https://app.storefries.com/login" style="width:120px">Sign in</a>
			</li>
		</ul>
		
	</div>
</nav>
</div>
