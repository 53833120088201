<div style="overflow:hidden">
    <!-- <section class="navigation">
      <div class="nav-container">
        <div class="brand">
          <a href="https://storefries.com/">
            <img src="../../../assets/logo (1)-01.png" style="height:60px">
          </a>
        </div>
        <nav>
          <div class="nav-mobile">
            <a id="nav-toggle" href="#!"><span></span></a>
          </div>
          <ul class="nav-list">
  
            <li>
              <a href="#!">Product</a>
              <ul class="nav-dropdown">
                <li><a target="_blank" href="https://storefries.com/content-discovery.html">Local Content</a></li>
                <li><a target="_blank" href="https://storefries.com/medialibrary.html">Media Library</a></li>
                <li><a  target="_blank" href="https://storefries.com/composer.html">Composer</a></li>
                <li><a target="_blank" href="https://storefries.com/planner.html">Plan & Schedule</a></li>
                <li><a target="_blank" href="https://storefries.com/social-inbox.html">Social Inbox</a></li>
                <li><a target="_blank" href="https://storefries.com/analytics.html">Analytics</a></li>
              </ul>
            </li>
            <li>
              <a href="#!">Solution</a>
              <ul class="nav-dropdown">
                <li><a target="_blank" href="https://storefries.com/features/geo-social-marketing.html">Geo Social Marketing</a></li>
                <li><a target="_blank" href="https://storefries.com/features/social-media-management.html">Social media management</a></li>
                <li><a target="_blank" href="https://storefries.com/features/curation.html">Content curation</a></li>
                <li><a target="_blank" href="https://storefries.com/features/social-engagement.html">Sociale media engagement</a></li>
                <li><a target="_blank" href="https://storefries.com/features/social-media-analytics.html">social media analytics</a></li>
                <li><a target="_blank" href="https://storefries.com/features/Small-Business.html">For Small Business</a></li>
                <li><a target="_blank" href="https://storefries.com/features/Retail.html">Retail</a></li>
              </ul>
            




            </li>
            <li><a target="_blank" href="https://storefries.com/pricing.html">Pricing</a></li>
            <li>
              <a href="#!">Resource</a>
              <ul class="nav-dropdown">
                <li><a target="_blank" href="https://storefries.com/Blog/storefries-blogs.html">Blogs</a></li>
                <li><a target="_blank" href="https://storefries.com/hc/helpcenter.html">Help center</a></li>
               
              </ul>
            </li>
            
            <a href="https://app.storefries.com/signup" type="button" class="btn btn-outline-success" style="margin-top:12px">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>&nbsp;Sign Up</a>&nbsp;
            <a href="https://app.storefries.com/login" type="button" class="btn btn-success" style="margin-top:12px">Get Started</a>
          </ul>
        </nav>
      </div>
    </section> -->
  
    <div style="background-color:#fff;">
      <div class="top-content">
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-6 col-12">
            <div class="banner">
            <div style="color: #fff;background-color: green;margin:auto;width:95%">
              <div class="top-hash">
                <h1>Best Hashtag Generator for Instagram is here!</h1>
                <p>Get More views and likes, Impressions and clicks, Reach and followers with our best in class Free
                  Hashtag Generator for Instagram</p>
              </div>
            </div>
          </div>
          <div class="form">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col-sm-8 col-12">
                  <!-- <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Enter the keyword" [(ngModel)]="keywords" > -->
                  <div class="form-group">
                    <!-- <label>Full Name</label> -->
                    <input type="text" formControlName="fullname" class="form-control" [(ngModel)]="keywords" placeholder="Enter the keyword" value="{{num4}}"
                      [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }" />
                  <div *ngIf="submitted && f.fullname.errors" class="invalid-feedback">
                        <div *ngIf="f.fullname.errors.required">Keyword Please</div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-12">
                  <div class="right-btn">
                  <!-- <button class="btn" (click)="retrivehashtag(keywords)">show</button> -->
                  <span (click)="tabName='Top-Hashtag'">
                    <!-- <button class="btn btn-primary" type="button" style="width:60%;" (click)="retrivehashtag()">Generate Hashtags!</button> -->
                    <div class="form-group">
                      <button type="submit" class="btn btn-primary" style="width:60%;" (click)="retrivehashtag()" >Generate
                        Hashtags!</button>
                    </div>
                  </span>
                </div>
              </div>
              </div>
            </form>
           
           <div class="col-md-4">
          
            <div class="try-ex">
           <a>Examples:</a>&nbsp;&nbsp;
           <span (click)="tabName='Top-Hashtag'">
             <a  (click)="retrivehashtag2('holidaycrazy')" style="color:blue;cursor: pointer;font-family: 'Poppins';font-size:15px;font-weight:300;">holidaycrazy</a>&nbsp;&nbsp;&nbsp;
             <a  (click)="retrivehashtag2('healthyeats')" style="color:blue;cursor: pointer;font-family: 'Poppins';font-size:15px;font-weight:300;" >healthyeats</a>&nbsp;&nbsp;&nbsp;
             <a  (click)="retrivehashtag2('picoftheday')" style="color:blue;cursor: pointer;font-family: 'Poppins';font-size:15px;font-weight:300;">picoftheday</a>

           </span>
            <!-- <a   style="color:blue">India</a>&nbsp;&nbsp;&nbsp;
            <a   style="color:blue" >Facebook</a>&nbsp;&nbsp;&nbsp;
            <a   style="color:blue">Instagram</a> -->
          </div>
            
          </div>
          
          </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-12">
            <div class="column-right">
            <img src="../../../assets/Hasahtag (2).png">
          </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="generate ">
      <mat-card class="bg-light"><span style="padding-left:50px">
          <p style="font-size:25px;font-family:'poppins';font-weight:600">Keyword</p>
        </span>
         <span style="padding-left:100px">Image</span> 
      </mat-card>
    </div> -->
  
      <!-- <div *ngIf="tabName === 'Hashtag-Generator'" style="background-color:#fff">
      <div class="content">
       <p class="size">Likes and Followers? Reach and Impressions? Money and Fame?</p>
       <p class="size">Pimp your Activity on Instagram! Find the top hashtags that drive the target audience to your page. 
        Fill your posts with the best selection from 12+ million hashtags divided into multiple categories, trending, and high-convertible.
        Use hashtags for Instagram, Facebook, Twitter, TikTok, and other social media.</p>
        <p class="heading">Hashtag Generator for Instagram</p>
        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Genarate By Keyword"> 
            <div class="form">
              <div class="row">
                <div class="col-8">
                  <input class="form-control form-control-lg" type="text"  aria-label=".form-control-lg example">
                </div>
                <div class="col-4">
                  <button type="button" class="btn btn-success btn-lg" style="width:100%">Generate Hashtags</button>
                </div>
                <p style="margin-top:15px;margin-left: 10px;">This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</p>
              </div>
          </div>
          </mat-tab>
          <mat-tab label="Generate By Url"> 
            <div class="form">
              <div class="row">
                <div class="col-8">
                  <input class="form-control form-control-lg" type="text"  aria-label=".form-control-lg example">
                </div>
                <div class="col-4">
                  <button type="button" class="btn btn-success btn-lg" style="width:100%">Generate Hashtags</button>
                </div>
                <p style="margin-top:15px;margin-left: 10px;">This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</p>
              </div>
              
          </div>
          </mat-tab>
          <mat-tab label="Generate By Photo">
            <div class="form">
              <div class="row">
                <div class="col-8">
                  <input class="form-control form-control-lg" type="file" placeholder="choose file" aria-label=".form-control-lg example" >
                 
                </div>
                <div class="col-4">
                  <button type="button" class="btn btn-success btn-lg" style="width:100%">Generate Hashtags</button>
                </div>
                <p style="margin-top:15px;margin-left: 10px;">This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</p>
              </div>
              
          </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div> -->
      <div style="background-color:#fff">
        <div class="content">
          
          <div class="loadingicon">
            <mat-spinner *ngIf="loading" [diameter]="30"></mat-spinner>
            
           
        </div>
          <div *ngIf="tabName === 'Top-Hashtag' && keywords !== undefined && keywords !== ''">
            <div class="row">
              <div class="hashtag">
                <mat-radio-button (click)="tabNamehash = 'Hashtag-Relevancy'" checked>Relevancy</mat-radio-button>
                &nbsp;&nbsp;&nbsp;
                <mat-radio-button (click)="tabNamehash = 'Hashtag-Count'">Reach</mat-radio-button>
              </div>
              <div class="col-sm-7 col-12">
                <div id="example">
                <div *ngIf="tabNamehash === 'Hashtag-Relevancy'">
                  <table mat-table [dataSource]="relevancyhashdata" class="mat-elevation-z8" matSort>
                    <ng-container matColumnDef="select">
                      <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="toggleSelectionAll()" [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                      </th>
                      <div *ngIf = "totalHashtag < 2">
                        <td mat-cell *matCellDef="let row">
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleSelection(row)"
                            [checked]="selection.isSelected(row)">
                          </mat-checkbox>
                        </td>
                      </div>
                      <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>
  
                    <ng-container matColumnDef="Tag">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tag</th>
                      <td mat-cell *matCellDef="let element"> {{element.tag}} </td>
                    </ng-container>
  
  
                    <ng-container matColumnDef="Count">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Count</th>
                      <td mat-cell *matCellDef="let element"> {{element.count}} </td>
                    </ng-container>
  
                    <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns4;" (click)="toggleSelection(row)"></tr>
                  </table>
                  <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
                </div>
                <div *ngIf="tabNamehash === 'Hashtag-Count'">
                  <table mat-table [dataSource]="counthashdata" class="mat-elevation-z8" matSort>
                    <ng-container matColumnDef="select">
                      <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="toggleSelectionAll2()"
                          [checked]="selection.hasValue() && isAllSelected2()"
                          [indeterminate]="selection.hasValue() && !isAllSelected2()">
                        </mat-checkbox>
                      </th>
  
                      <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleSelection2(row)"
                          [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                      </td>
                      <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>
                    <ng-container matColumnDef="Tag">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tag</th>
                      <td mat-cell *matCellDef="let element"> {{element.tag}} </td>
                    </ng-container>
  
  
                    <ng-container matColumnDef="Count">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Count</th>
                      <!-- <td mat-cell *matCellDef="let element"> {{element.count}} </td> -->
                      <td mat-cell *matCellDef="let element" ><!--  {{element.count}} -->{{element.count}} </td>
                    </ng-container>
  
                    <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns4;"(click)="toggleSelection2(row)"></tr>
                  </table>
                </div>
              </div>
                
  
              </div>
              <div class="col-sm-5 col-12">
                <label for="clipboard-example-textarea">Text to be copied</label>
  
                <textarea type="text" value="{{totalCal}} " #userinput  rows="10"> </textarea>
                <p style="margin-top:20px"><span style="font-size:18px;font-family:'poppins';font-weight:600">Add tags count:</span >&nbsp;&nbsp;
                  <span style="font-size:18px;font-family:'poppins';font-weight:400">{{select}}/20</span> </p>
                <div style="margin-top:20px">
                  <button class="btn btn-success" (click)="copyInputMessage(userinput)">Copy</button>
                  <button class="btn btn-danger clean" (click)="toggledeleteAll()" style="float:right">Delete</button>
               
                </div>
              </div>
            </div>
          </div>
          
        </div>
  
      </div>
    
   
    <br />
  
  <hr>
   <h1 class="text-center" style="padding-top:20px;font-weight:600; font-family: 'Poppins'">Perks of Using Instagram Hashtags!
  </h1>
   <p class="mx-auto" style="width: 60%;text-align: center;font-family: 'Poppins';font-size:15px;font-weight:300;">
    Storefries Instagram Hashtag Generator furnishes you with specific hashtags which help you to increase your brand exposure. How could these hashtags assist you to build your brand image on Instagram? These hashtags can profit you in three ways:
</p>
<hr class="hr">
    <div class="contents">
     <div class="row" >
      <div class="col-lg-7 col-md-7 col-sm-7 col-12">
        <div >
        <h1>Get more engagements with hashtags
        </h1>
        <p class="para-res" >People should see your posts for your brand to find success on Instagram. Hashtags are the gateway to success on Instagram! When you use the correct Hashtags you will see a hike in the engagement for your posts.</p>
        <p class="para-res" >You may also come on first on the user's feed by choosing the right hashtags related to your keyword using our Instagram hashtag generator. Instagram's algorithm customizes the user's feed with the data that users might like to interact with. No, you can understand the importance of hashtags on the posts.
        </p> 
      </div> 
      
      </div>
      
     <div class="col-lg-5 col-md-5 col-sm-5 col-12">
      <img src="../../assets/Engagement_HASHTAG.png" style="width:100%">
      
    </div>
    </div>
    <hr class="hr">
    <div class="row" style="margin-top:50px">  
     <div class="col-lg-7 col-md-7 col-sm-7 col-12">
      
      <img src="../../assets/Construct image_HASHTAG.png" style="width:80%">

    </div>
    <div class="col-lg-5 col-md-5 col-sm-5 col-12">
      <h1>Construct your image with Instagram Hashtags
      </h1>
      <p class="para-res">Branding and reaching out to multiple audiences are the best purposes of hashtags on Instagram. If you own a new business stumbling to promote your products to the users you can use hashtags to extend your audience reach and increase brand awareness. 

      </p>
      <p class="para-res">At the point when clients search for your brand, they'll see the significant hashtags you're utilizing; or when they search for explicit hashtags, they'll see your marked posts in the outcomes. This normally brings about acquiring a new audience and some new clients for your brand. You can also make your hashtag for a product, service, or event you're promoting. You can ask your supporters and clients to utilize the hashtag when they buy your products.</p>
      </div>
    </div>
    <div class="row" style="margin-top:80px">
      <div class="col-lg-7 col-md-7 col-sm-7 col-12">
        <h1>Promoting and campaigns
        </h1>
        <p class="para-res" >One of the fundamental reasons hashtags have acquired such an important position is their capacity to help advertisers and organizations with making custom user-based campaigns. when you post about a promotion post, then your followers will also use the same hashtag, then their followers will utilize it too it goes on, etc. 
        </p>
        <p class="para-res" >Hashtags make incredible perceivability for a campaign and assist you with getting the interested audience by important hashtags that you understand where your listeners might be coming from or maybe following it. Another helpful way to use hashtags to elevate your business is to use them with a giveaway promotion.
        </p>
         
      </div>
     <div class="col-lg-5 col-md-5 col-sm-5 col-12">
      <img src="../../assets/Promote_and_campaign.png" style="width:100%">
    </div>
    </div>
    </div>
    <hr>
    <h1 class="text-center" style="padding-top:30px;font-weight:600; font-family: 'Poppins'">How Storefries Instagram Hashtag Generator Works?
    </h1>
 
    <p  class="mx-auto" style="width: 60%;text-align: center;font-family: 'Poppins';font-size:15px;font-weight:300;">
      Hashtag-focused posts get high engagement than non-hashtag-updated posts. It's proved that! You'll get more traffic, preferences, and followers assuming when you utilize the correct hashtags. So Storefries has created this Instagram Hashtag Generator to find hashtags that will increase your engagement with your followers.
    </p>
    <div class="contents">
      <!-- <div class="row" >
       <div class="col-lg-7 col-md-7 col-sm-7 col-12">
         <h1>How to use Storefries Instagram Hashtag Generator?</h1>
         <p class="" style="width: 60%;">Our Instagram Hashtag Generator is a free tool.
          It will pick out the hashtag created by artificial intelligence to give you relevant and high-engagement hashtags.
          It searches the Instagram algorithm depending on your keyword preferences added to it.
         </p>
         <h1>Best and relevant hashtags
        </h1>
         <p class="" style="width: 60%;">
          Storefries Free Instagram Hashtag Generator is a simple tool to let you expand your Instagram business reach to the next stage and give you valuable clients. In the light second when you add your hashtag to the input field, it naturally creates many quality hashtags. All with a single press!

        </p>
          </div>
      <div class="col-lg-5 col-md-5 col-sm-5 col-12">
       Image
     </div>
     </div> -->
     <div class="row" >  
      <div class="col-lg-7 col-md-7 col-sm-7 col-12">
        <img src="../../assets/Instagram hashtag generator.png" style="width:80%">
     </div>
     <div class="col-lg-5 col-md-5 col-sm-5 col-12">
      <h1>How to use Storefries Instagram Hashtag Generator?</h1>
      <p class="para-res" >Our Instagram Hashtag Generator is a free tool.
       It will pick out the hashtag created by artificial intelligence to give you relevant and high-engagement hashtags.
       It searches the Instagram algorithm depending on your keyword preferences added to it.
      </p>
      <h1>Best and relevant hashtags
     </h1>
      <p class="para-res" >
       Storefries Free Instagram Hashtag Generator is a simple tool to let you expand your Instagram business reach to the next stage and give you valuable clients. In the light second when you add your hashtag to the input field, it naturally creates many quality hashtags. All with a single press!

     </p>
       <h1>Hashtags with volume
      </h1>
      <p class="para-res">See the various possibility of hashtags created by AI. By looking at the search volume you can read about the hashtag value and choose the one which you think might be a good choice. Doing this ensures you generally get the best performing hashtags available all the time.

      </p>
    </div>
     </div>
    </div>
    
   
  </div>
  